// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Resources } from '../../Commons/properties/Resources';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { TerceraClosePositionBodyTemplate, TerceraClosePositionScreenTemplate, TerceraClosePositionFooterTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from '../panels/OrderEditViewBase';
import { TerceraWindowBase } from './TerceraWindowBase';
import { ThemeManager } from '../misc/ThemeManager';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { GeneralSettings } from '../../Utils/GeneralSettings/GeneralSettings';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { IsAllowed } from '../../Commons/IsAllowed';
import { Quantity } from '../../Utils/Trading/Quantity';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { DataCache } from '../../Commons/DataCache';
import { TradingNumericErrorChecker } from '../../Commons/Trading/TradingNumericErrorChecker';
import { MathUtils } from '../../Utils/MathUtils';
import moment from 'moment';
import { type Position } from '../../Commons/cache/Position';
import { MarginInfoParameters } from '../../Commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { MarginInfoWrapper } from '../../Commons/UtilsClasses/MarginInfo/MarginInfoWrapper';

// constructor
export class TerceraClosePositionScreen extends TerceraWindowBase {
    public FPosition: any = null;

    constructor () { super(); }

    public override getType (): ScreensNames { return ScreensNames.ClosePositionScreen; }

    // TerceraClosePositionScreen.show = function (positionId, placedFrom)     // old and useless -> check EditPositionScreen
    // {
    //     var screen = Factory.addScreen(ScreensNames.ClosePositionScreen);
    //     screen.set("PositionID", positionId);
    //     screen.set("placedFrom", placedFrom);
    // };

    public override oncomplete (): void {
        this.FPosition = null;
        super.oncomplete();
        this.onrender();

        this.on('closeClick', this.closeClick);
        this.on('cancelClick', this.cancelClick);
        this.observe('AmountToClose', this.changeAmountToClose);
        void this.set('checkedShowNextTime', GeneralSettings.Confirmations.useConfirmation(ConfirmationTypesEnum.PositionClose));

        const position = this.get('position');
        void this.fillByPositionAsync(position);
        this.FPosition = position;
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    public override oninit (): void {
        super.oninit();

        void this.set('numericsErrors', {});

        this.localize();
    }

    public closeClick (): void {
        this.closePos();

        const parentContainerControl = this.get('parentContainerControl');

        if (parentContainerControl) {
            parentContainerControl.close();
        }
    }

    public cancelClick (): void {
        const parentContainerControl = this.get('parentContainerControl');

        if (parentContainerControl) {
            parentContainerControl.close();
        }
    }

    private async GetTotalFeeFromMarginRequest (position: Position, amountToClose: number = null): Promise<string> {
        const parameters = new MarginInfoParameters();
        parameters.account = position.Account;
        parameters.instrument = position.Instrument;
        parameters.orderType = OrderType.Market;
        let lotsToClose;
        if (!isNullOrUndefined(amountToClose)) {
            const qty = new Quantity(amountToClose, GeneralSettings.View.DisplayQuantityInLots);
            lotsToClose = Quantity.toLots(qty, position.Instrument, position.Account, position.ProductType);
        } else {
            lotsToClose = position.Amount;
        }

        parameters.amountLots = lotsToClose;
        parameters.productType = position.ProductType;
        parameters.isLong = position.BuySell === OperationType.Buy;

        const marginInfoWrapper = new MarginInfoWrapper();
        const totalFee = await marginInfoWrapper.GetTotalFeeFromMarginRequest(parameters);
        let totalFeeStr = '-';
        if (!isNaN(totalFee) && !isNullOrUndefined(totalFee)) {
            totalFeeStr = position.Account.assetBalanceDefault.formatPrice(Math.abs(totalFee));
        }

        return totalFeeStr;
    }

    public async fillByPositionAsync (position: Position): Promise<void> {
        const isBuy = position.BuySell === OperationType.Buy;
        const instrument = position.Instrument;
        const account = position.Account;
        const productType = position.ProductType;
        void this.GetTotalFeeFromMarginRequest(position).then((result: string) => {
            void this.set({ TotalFee: result });
        });

        await this.set({
            InstrumentName: instrument.DisplayName(),

            isBuy,
            OperationType: Resources.getResource(isBuy ? 'general.trading.position.Buy' : 'general.trading.position.Sell'),

            OpenPrice: instrument.formatPrice(position.OpenPrice),
            CurrentPrice: instrument.formatPrice(position.CurPriceClose),
            CloseGrossPL: account.formatPrice(position.getGrossPnL(false)),
            AccountName: position.Account.FullAccString,
            OrderType: Resources.getResource('property.' + OrderUtils.getOrderTypeLocalizationKey(OrderType.Market)),
            Time: moment(position.OpenTime).format('DD.MM.YYYY HH:mm:ss'),
            insName: instrument.GetInteriorID(),
            leverage: OrderUtils.GetFormattedLeverage(position.Leverage),
            productType: InstrumentUtils.GetLocalizedProductType(instrument, productType),
            showProductType: instrument.isProductTypeVisible(),
            TotalFee: '-'
        });

        const inLots = GeneralSettings.View.DisplayQuantityInLots;
        const lotSize = instrument.getLotSize();
        const amountMultiplier = inLots ? 1 : lotSize;

        let amountPrecision = instrument.getAmountPrecision(inLots, account, productType);

        let convertedAmount = inLots
            ? position.Amount
            : position.Amount * amountMultiplier;

        let SkipValidationForMaxValue = false;

        const lotStep = instrument.getLotStep(productType, account);

        if (MathUtils.mod(convertedAmount, lotStep) != 0) { // #118192: Close Positions with Qty < LotSize
            amountPrecision = MathUtils.getPrecision(convertedAmount);
            SkipValidationForMaxValue = true;
        }

        if (!inLots) {
            convertedAmount = parseFloat(convertedAmount.toFixed(amountPrecision));
        }

        await this.set('Amount', convertedAmount);

        if (!instrument.isHideRouteMode) {
            await this.set({ Route: position.Route, showRoute: true });
        } else {
            await this.set({ showRoute: false });
        }

        const routeOffset = instrument.isHideRouteMode ? 0 : 20; // calcOffsets
        const leverageOffset = routeOffset + (this.get('leverage') ? 20 : 0); // delete after UI_Refactoring TODO
        const productTypeOffset = leverageOffset + (this.get('showProductType') ? 20 : 0);
        await this.set({
            routeOffset,
            leverageOffset, // delete after UI_Refactoring TODO
            productTypeOffset
        }); // calcOffsets end

        let step = lotStep * amountMultiplier;
        step = parseFloat(step.toFixed(amountPrecision));

        const minLot = instrument.getMinLot(productType, account);
        if (convertedAmount < minLot) {
            step = convertedAmount; // to disable numeric for #118192: Close Positions with Qty < LotSize
        }

        await this.set({
            amountPrec: amountPrecision,
            lotStep: step,
            minAmountToClose: step,
            maxAmountToClose: convertedAmount,
            AmountToClose: convertedAmount,
            SkipValidationForMaxValue,
            allowAmountEdit: step !== convertedAmount
        });

        this.updateQtyToCloseMoreThenMaxLotWarning(position);

        this.addAdditionalInfo(instrument, account);

        // TODO
        await this.set({ partialCloseAllowed: true/* updatePackage.modifyRules[TerceraModifyScreen.AllowedModifyOrderRules.allowPartialClose] */ });

        this.fire('onFillByPosition');
    }

    public addAdditionalInfo (instrument, account): void {
        const additionalInfo = account.GetCommissionInfo(instrument);
        if (!additionalInfo) {
            return;
        }

        void this.set('additionalInfo', additionalInfo);
        const el = this.find('.confirmFeeInfoContainer');
        if (el) {
            HtmlScroll.addScroll(el);
        }
    }

    public closePos (): void {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        // TODO. When numeric is disabled, it throws 'Value is empty' error.
        if (this.get('allowAmountEdit') &&
        !OrderEditViewBase.CheckNumericsErrors(this.get('numericsErrors'))) {
            return;
        }

        const dc = DataCache;

        const qty = new Quantity(
            this.get('AmountToClose'),
            GeneralSettings.View.DisplayQuantityInLots);

        dc.FOrderExecutor.closePositionQuantity(
            this.get('position'),
            qty,
            this.get('placedFrom')
        );
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('screen.closePosition.title'),
            positionIdText: Resources.getResource('panel.positions.pos_number') + ':',
            symbolText: Resources.getResource('panel.positions.Symbol') + ':',
            routeText: Resources.getResource('panel.positions.route') + ':',
            sideText: Resources.getResource('panel.positions.Operation') + ':',
            leverageText: Resources.getResource('panel.positions.Leverage') + ':',
            quantityText: Resources.getResource('panel.closePosition.QunatityToClose') + ':',
            orderTypeText: Resources.getResource('panel.positions.OrderType') + ':',
            openPriceText: Resources.getResource('panel.positions.Basis') + ':',
            currentPriceText: Resources.getResource('panel.positions.cur_price') + ':',
            grossPLText: Resources.getResource('panel.closePosition.Gross_P/L_ToClose') + ':',
            accountText: Resources.getResource('panel.positions.Account') + ':',
            dateTimeText: Resources.getResource('panel.positions.date_time') + ':',
            totalFeeText: Resources.getResource('screen.closePosition.EstimatedClosingFee') + ':',
            errorText: Resources.getResource('screen.closePosition.maxLotWarning'),
            productTypeText: Resources.getResource('panel.closePosition.productTypeText') + ':',
            showNextTimeText: Resources.getResource('general.messageBox.showNextTime'),
            closeText: Resources.getResource('screen.closePosition.ok'),
            cancelText: Resources.getResource('screen.closePosition.cancel')
        });
    }

    public TickAsync (): void {
        const pos = this.FPosition;
        let amount = this.get('AmountToClose');
        if (!GeneralSettings.View.DisplayQuantityInLots) {
            amount = amount / pos.Instrument.LotSize;
        }

        this.updateQtyToCloseMoreThenMaxLotWarning(pos);

        void this.set({
            OpenPrice: pos.Instrument.formatPrice(pos.OpenPrice),
            CurrentPrice: pos.Instrument.formatPrice(pos.CurPriceClose),
            CloseGrossPL: pos.Account.formatPrice(pos.PnLCalculator.CalculateAccNetPLByQty(amount))
        });
    }

    public override dispose (): void {
        GeneralSettings.Confirmations.updateConfirmation(ConfirmationTypesEnum.PositionClose, this.get('checkedShowNextTime'));
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        super.dispose();
    }

    public updateQtyToCloseMoreThenMaxLotWarning (position): void {
        if (GeneralSettings.Warnings.WarnIfQtyToCloseMoreThanMaxLot) {
            const qtyToClose = this.get('AmountToClose');
            const newErrorMode = IsAllowed.IsMaxLotHasBeenReached(position, qtyToClose);
            void this.set({ errorMode: newErrorMode, pictureErrorUrl: ThemeManager.getImageFullUrl('i_messagebox/32x32_icon_warning.png') });
        }
    }

    public changeAmountToClose (newValue): void {
        if (!isNullOrUndefined(this.FPosition)) {
            void this.GetTotalFeeFromMarginRequest(this.FPosition, newValue).then((result: string) => {
                void this.set({ TotalFee: result });
            });
        }
    }
}

TerceraWindowBase.extendWith(TerceraClosePositionScreen, {
    data: function () {
        return {
            resizable: false,
            position: null,
            InstrumentName: 'N/A',
            Route: 'N/A',
            OperationType: 'N/A',
            productType: 'N/A',
            Amount: 0.01,
            OpenPrice: 'N/A',
            CurrentPrice: 'N/A',
            CloseGrossPL: 'N/A',
            AccountName: 'N/A',
            OrderType: 'Market',
            Time: 'N/A',
            TotalFee: '-',
            width: 395,
            // height: 330,
            insName: null,
            AmountToClose: null,
            lotStep: 0.01,
            amountPrec: 0.01,
            maxAmountToClose: 0.1,
            minAmountToClose: 0.1,
            partialCloseAllowed: false,
            numericsErrors: {},
            showRoute: true,
            showProductType: false,
            showHeader: false,
            showBorder: false,
            isFIFOPosition: false,
            placedFrom: null,
            // showFullscreenCloud: true, //TODO 83829
            errorTop: 7,
            errorMode: false,
            errorText: '',
            pictureErrorUrl: '',

            header: 'Close position',
            positionIdText: 'Position ID:',
            symbolText: 'Symbol:',
            routeText: 'Route:',
            sideText: 'Side:',
            leverageText: 'Leverage:',
            productTypeText: 'Product type:',
            quantityText: 'Quantity to close:',
            orderTypeText: 'Order type:',
            openPriceText: 'Open price:',
            currentPriceText: 'Current price:',
            grossPLText: 'Gross P/L to close:',
            accountText: 'Account:',
            additionalInfo: '',
            dateTimeText: 'Date/Time:',
            totalFeeText: 'Estimated closing fee:'
        };
    },
    computed: {
        formattedCurrentPrice: {
            get: function () {
                const ins = DataCache.getInstrumentByName(this.get('insName'));
                const curPrice = this.get('CurrentPrice');
                return ins ? ins.formatPrice(curPrice) : curPrice;
            },
            set: function () { }
        },

        sideStyle: function () {
            const color = ThemeManager.CurrentTheme[this.get('isBuy') ? 'BuyColor' : 'SellColor'];
            return 'color:' + color + ';';
        },
        grossPLStyle: function () {
            const grossPLStr = this.get('CloseGrossPL');
            const color = ThemeManager.CurrentTheme[
                !grossPLStr || grossPLStr.indexOf('-') === 0
                    ? 'TableValueDownForeColor'
                    : 'TableValueUpForeColor'
            ];
            return 'color:' + color + ';';
        }
    },
    template: TerceraClosePositionScreenTemplate,
    partials: {
        bodyPartial: TerceraClosePositionBodyTemplate,
        footerPartial: TerceraClosePositionFooterTemplate
    }
});
