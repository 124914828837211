// Copyright TraderEvolution Global LTD. © 2017-2024. All rights reserved.

import { Point, Rectangle } from '../../../Commons/Geometry';
import { Color, Font, Pen, SolidBrush } from '../../../Commons/Graphics';
import { LinkedSystem, LinkedSystemAccLinkingValue } from '../../misc/LinkedSystem';
import { AlertSubImage } from '../../../Utils/Alert/AlertConstants';
import { KeyCode, KeyEventProcessor } from '../../../Commons/KeyEventProcessor';
import { MouseButtons } from '../../UtilsClasses/ControlsUtils';
import { MathUtils } from '../../../Utils/MathUtils';
import { contextMenuHandler } from '../../../Utils/AppHandlers';
import { Resources } from '../../../Commons/properties/Resources';
import { Scroll } from '../Scroll';
import { QuickTableColumn, QTSortRuleType } from './QuickTableColumn';
import { QuickTableColumnType, type QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { QuickTableRow } from '../QuickTable/QuickTableRow';
import { QuickTableGroupRow } from '../QuickTable/QuickTableGroupRow';
import { QuickTableUtils } from './QuickTableUtils';
import { CustomEvent } from '../../../Utils/CustomEvents';
import { ThemeManager } from '../../misc/ThemeManager';
import { ArrayUtils } from '../../../Utils/ArrayUtils';
import { ScrollUtils } from '../../UtilsClasses/ScrollUtils';
import { DynProperty } from '../../../Commons/DynProperty';
import { InterTraderBtnStatus } from '../../../Utils/Instruments/InterTraderBtnStatus';
import { BrowserUtils } from '../../../Commons/UtilsClasses/BrowserUtils';
import { type BaseItem } from '../../cache/BaseItem';
import { HitTestInfo } from './WorkingModels/HitTestInfo';
import { type QuickTableCell } from './QuickTableCell';
import { type InstrumentLookupAutoCompleteQuickTableCell } from './InstrumentLookupAutoCompleteQuickTableCell';
import $ from 'jquery';
import { QuickTableResizer, QuickTableResizerItem } from './Resizers/QuickTableResizer';

// TODO. Use public getRowsArrayToDraw() for drawing/selection instead of rowsArray.

export class QuickTable<ItemType extends BaseItem = any> {
    public context: CanvasRenderingContext2D;

    public needRedrawBackground = true;
    public needRedraw = true;
    public rowCountChanged = false;

    public resizingNow = false;
    public lazyResizing: boolean; // should depend on browser

    public showHeader = true;
    public enableRowHover = false;
    public enableRowHoverBorder = false;
    public isRowSeparated = false;

    public AddToEnd = true;

    // Rows [QuickTableRow]
    public rows: Record<any, QuickTableRow<ItemType>> = {};
    public rowsArray: Array<QuickTableRow<ItemType> | QuickTableGroupRow<ItemType>> = [];

    // Columns [QuickTableColumn]
    public columns: QuickTableColumn[] = [];
    public sortedColumns: QuickTableColumn[] = [];

    public lastVisibleSortedColumnIdx = -1;
    public isMoveColumnAllowed = true;
    //
    public width = 100;
    private prevWidth = 100;
    public height = 100;

    public rowHeight = 25;
    public borderWidth = 1;
    public separatedRowWidth = 1;
    public separatedColumnWidth = 1;

    //
    public verticalScrollPos = 0;
    public selectedRowIds = [];

    public sortIndex = -1;
    public lockManualSorting = false;
    public columnResizeAllowed = true;
    public canShowHeaderMenu = true;
    public asc = true;

    public canUserCollapseGroups = true;

    public useVerticalSeparatorForResizing = false;

    public groupByColumnIndex = -1;
    public groupStorageDict = {};

    public movingType = QuickTableMovingType.None;
    public activeColumnIndex = -1;
    public lastMouseMoveHitTestInfo: HitTestInfo = null;
    public lastWKoef = 1;

    public headerContextMenuItems: any = null;
    public tableContextMenuItems: any = null;
    public groupByContextMenuItems: any = null;

    public allowGroupBy = true;

    public scroll: Scroll;

    public isReady = false;
    public isUpdatingNow = false;

    public ShowTotals = false;
    public additionalFilter = false; // if true: in filtration skip items with isSubItem=true FOR FIFO POSITIONS (= displayTrades) #80010
    public skipAccountFiltration = false;
    // public gridHorizontalVisible = false;                      // table horizontal grid visibility
    // public gridVerticalVisible = false;                        // table vertical grid visibility

    public OnAddItem = new CustomEvent();
    public OnSelectionChanged = new CustomEvent();
    public OnVisibleColumnChanged = new CustomEvent();
    public OnColumnResize = new CustomEvent();
    public ColumnDisplayIndexChanged = new CustomEvent();
    public ClickableCellsIndexesAndEvents = {};

    public OnDrawCell = new CustomEvent();

    public AfterEditItem = new CustomEvent();
    public AfterButtonItem = new CustomEvent();
    public AfterExpandItem = new CustomEvent(); // (event for collapse FIFO pos) #80010
    public OnPaintedPictureButtonClick = new CustomEvent();

    public OnExternalCellEditRequest = new CustomEvent();

    // TODO. Wrong concept.
    public OnExternalShowTooltipRequest = new CustomEvent();

    public OnGroupsVisibilityChanged = new CustomEvent();

    public currentCursor = 'default';

    public beforeTableContextMenuShowing: any = null;

    public lastMouseDown = new Point();
    public lastMouseMove: Point | null;
    public columnReordering = false;
    public mouseDownWasOnMe = false;

    public cellFontSize = 1;

    public FormatGroup: any = null; // function

    public AssociatedItem: BaseItem | null = null;

    public onTableMouseUP = new CustomEvent();
    public onTableMouseDown = new CustomEvent();
    public onTableMouseDoubleClick = new CustomEvent();
    public onTableMouseMove = new CustomEvent();
    public OnShowTooltip = new CustomEvent();

    public editableRowID: any = null;
    public editableColID: any = null;

    public columnsIndexWithColoringByPrevValue = [];
    public CustomTextAlign: CanvasTextAlign | null = null;
    public GroupIndexSorting = false;

    public ColorHeaderText: string | null = null;

    public isActiveEditControl = false;
    public BackColor: string | CanvasGradient | CanvasPattern;
    public gridColor: string | CanvasGradient | CanvasPattern;
    public headerFont: string;
    public headerBackColor: string | CanvasGradient | CanvasPattern;
    public headerForeColor: string;
    public cellFont: string;
    public cellFontObj: Font;
    public rowBackColor: any;
    public selectedBackColor: any;
    public hoveredBackColor: any;
    public hoveredBorderColor: string;
    public alternatingRowBackColor: any;
    public highlightSuperItemBackColor: any;
    public editHighlightPen: Pen;
    public totalsForeColor: string | CanvasGradient | CanvasPattern;
    public totalsBackColor: string | CanvasGradient | CanvasPattern;
    public totalCurrency: any;
    public columnSliderColor: string | CanvasGradient | CanvasPattern;
    public dragDefaultPen: any;
    public dragHighlightPen: any;
    public headerBackBrush: SolidBrush;
    public verticalScrollClickDelta: number;
    public headerTextColorBrush: SolidBrush;
    public ForeColor: any;
    public color: any;
    public selectedForeColor: any;
    public hoveredForeColor: any;
    public alternatingRowForeColor: any;
    public headerShadowColor: any;
    public tableGroupOpenImg: any;
    public tableGroupClosedImg: any;
    public tableGroupRowDefaultBrush: SolidBrush;
    public tableGroupRowHoveredBrush: SolidBrush;
    public HeaderFont: any;
    public LinkText: string;
    public lastSelectedRowId: any;
    public hoveredRow: QuickTableRow<ItemType> = null;
    public needRefindHoveredRow: boolean = false;
    public isVertical: boolean = false;

    public tableResizer: QuickTableResizer = new QuickTableResizer();

    constructor (drawingContext) {
        this.context = drawingContext;

        this.lazyResizing = BrowserUtils.isIE();

        const scrollRect = this.getScrollRect();
        this.scroll = new Scroll(scrollRect.X, scrollRect.Y, scrollRect.Width, scrollRect.Height, 0, 0, this.context, this.rowHeight);
        this.scroll.OnValueChange.Subscribe(this.OnScroll.bind(this), this);
    }

    public Dispose (): void {
        if (!isNullOrUndefined(this.context)) {
            $(this.context.canvas.id + '_backbuffer').remove();
        }

        this.beforeTableContextMenuShowing = null;

        this.ClearAll();
    }

    public OnScroll (): void {
        if (this.enableRowHover) this.needRefindHoveredRow = true;
        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public Draw (): void {
        if (isNullOrUndefined(this.context)) return;
        // Skip drawing during resize for bad performance browsers (IE)
        if (this.resizingNow && this.lazyResizing) return;

        const context = this.context;
        context.save();
        // all drawing settings
        context.textBaseline = 'top';
        context.imageSmoothingEnabled = true;
        const ClientW = Math.floor(this.width);
        const ClientH = Math.floor(this.height);

        // Полная/частичная прорисовка?
        const wasFullDrawing = this.needRedrawBackground;

        if (wasFullDrawing) {
            context.fillStyle = this.BackColor;
            context.fillRect(0, 0, ClientW, ClientH);
        }

        // Draw columns

        // calc autosize width
        let wKoef = 1;
        let totalW = 0;

        let colLen = this.columns.length;
        for (let i = 0; i < colLen; i++) {
            if (!this.columns[i].visible || this.columns[i].hidden) {
                continue;
            }

            totalW += this.columns[i].width;
        }

        wKoef = totalW > 0 ? ClientW / totalW : 0;

        // vertical lines and headers
        let curX = 0;
        context.beginPath();
        context.strokeStyle = this.gridColor;
        context.lineWidth = 1;
        // context.textBaseline = 'middle';
        context.textAlign = 'center';
        context.font = this.headerFont;
        // context.moveTo(curX, 0);
        // context.lineTo(curX, ClientH);

        const sortedColumns = this.sortedColumns;
        for (let i = 0; i < colLen; i++) {
            const curColumn = sortedColumns[i];
            if (!curColumn.visible || curColumn.hidden) {
                continue;
            }

            let drawingWidth = Math.ceil(curColumn.width * wKoef);

            if (drawingWidth + curX >= this.width) {
                drawingWidth = this.width - this.borderWidth - curX;
            }

            curColumn.PRIVATE.drawingWidth = drawingWidth;
            curColumn.PRIVATE.drawingX = curX;

            if (this.showHeader) {
            // header
                context.fillStyle = this.headerBackColor;
                context.fillRect(
                    curX + this.borderWidth,
                    this.borderWidth,
                    drawingWidth + 2 * this.borderWidth,
                    this.rowHeight - 2 * this.borderWidth);

                let textX = curX + this.borderWidth;
                let textDrawingWidth = drawingWidth - this.borderWidth;

                const isSortCol = curColumn.PRIVATE.index === this.sortIndex;
                if (isSortCol) {
                    const sortImg = this.asc
                        ? ThemeManager.CurrentTheme.sortUpImg
                        : ThemeManager.CurrentTheme.sortDownImg;

                    if (sortImg) {
                        context.drawImage(sortImg, curX - 0.5, -1.5);
                    }

                    textX += 14;
                    textDrawingWidth -= 14;
                }

                // context.save();
                // Header text shadow.
                // if (this.headerShadowColor)
                // {
                //    context.shadowColor = this.headerShadowColor;
                //    context.shadowOffsetY = 1;
                // }
                // header text
                context.fillStyle = this.ColorHeaderText || this.headerForeColor;

                let headerX = textX + textDrawingWidth / 2;
                const headerY = 5;
                // const headerMaxWidth = textDrawingWidth - 2 * this.borderWidth;
                if (curColumn.headerText.length * 6.2 > textDrawingWidth) {
                    headerX += (curColumn.headerText.length * 6.2 - textDrawingWidth) / 2;
                }
                context.fillText(curColumn.headerText,
                    headerX,
                    headerY);
            // headerMaxWidth > 0 ? headerMaxWidth : 0);
            //
            // context.restore();
            }

            curX += drawingWidth;
        }

        context.stroke();

        const visibleRowsIndices = [];
        const lenCounter = this.rowsArray.length;
        for (let counter = 0; counter < lenCounter; counter++) {
            if (this.rowsArray[counter].visible) {
                visibleRowsIndices.push(counter);
            }
        }

        const firstVisibleScrollIdx = visibleRowsIndices[this.scroll.scrollIndex];

        // Rows display rect creation.
        // TODO. Why is it here?
        let curY = this.showHeader ? this.rowHeight : this.borderWidth;

        let curTotal = 0;
        let rowK0 = null;
        if (this.ShowTotals) {
            curTotal = 25;
            const keys = Object.keys(this.rows);
            rowK0 = this.rows[keys[0]];
        }

        let rowLen = this.rowsArray.length;
        const increaserByY = this.isRowSeparated ? this.rowHeight + this.separatedRowWidth : this.rowHeight;
        for (let ii = firstVisibleScrollIdx; ii < rowLen; ii++) {
            const row = this.rowsArray[ii];
            if (!row.visible) continue;

            // remember rect
            const rect = row.displayRectangle;
            rect.X = 0;
            rect.Y = curY;
            rect.Width = ClientW;
            rect.Height = this.rowHeight;

            curY += increaserByY;

            if (curY > ClientH - curTotal) {
                break;
            }
        }

        this.lastWKoef = wKoef;

        if (this.needRefindHoveredRow && !isNullOrUndefined(this.lastMouseMove)) {
            const newTestInfo = this.HitTest(this.lastMouseMove.X, this.lastMouseMove.Y);
            this.ChangeHoverRow(newTestInfo);
            this.needRefindHoveredRow = false;
        }

        // Draw cell data
        context.font = this.cellFont;
        const cellFontSize = this.cellFontSize;

        curX = 0;
        curY = 0;
        rowLen = this.rowsArray.length;
        colLen = this.sortedColumns.length;

        const lastMouseMove = this.lastMouseMove;
        const lastMouseMoveX = !isNullOrUndefined(lastMouseMove) ? lastMouseMove.X : 0;
        const lastMouseMoveY = !isNullOrUndefined(lastMouseMove) ? lastMouseMove.Y : 0;

        const groupRowIndicesToDrawSet = {};

        for (let j = 0; j < colLen; j++) {
            let summTotalValue = null;
            let totalGroupRow = null;
            const curColumn = this.sortedColumns[j];
            if (!curColumn.visible || curColumn.hidden) {
                continue;
            }

            // Get text align by column sorttype
            let colTextAlign: CanvasTextAlign = 'start';
            if (curColumn.sortRule === QTSortRuleType.Numeric) {
                colTextAlign = 'end';
            }

            if (!isNullOrUndefined(this.CustomTextAlign)) {
                colTextAlign = this.CustomTextAlign;
            }
            context.textAlign = colTextAlign;

            // Clip by column
            context.save();
            context.beginPath();

            const clipLeft = curX + this.borderWidth;
            const clipDrawingWidth = curColumn.PRIVATE.drawingWidth - this.borderWidth;
            const clipHeight = ClientH - this.borderWidth - 1;

            context.rect(
                clipLeft,
                this.borderWidth,
                clipDrawingWidth,
                clipHeight);

            context.clip();

            curY = this.showHeader ? this.rowHeight : this.borderWidth;

            const cellX = curX;
            let cellY = curY;
            const cellW = curColumn.PRIVATE.drawingWidth;
            const cellH = this.rowHeight;

            for (let i = 0; i < rowLen; i++) {
                const row = this.rowsArray[i];
                const cell = row.cells[curColumn.PRIVATE.index];
                if (!cell) { continue; }

                if (!row.visible) {
                    continue;
                }

                if (curColumn.ShowTotal) {
                    if (this.totalCurrency == null) {
                        this.filterByAccountNumber();
                    }
                    const value = !isNullOrUndefined(row.item) ? row.item.getValueForTotalCalculation(curColumn.PRIVATE.index, this.totalCurrency) : cell.value;
                    summTotalValue += value;
                    if (!isNullOrUndefined(totalGroupRow)) {
                        totalGroupRow[j].value += value;
                    }
                }

                if (curY > ClientH - curTotal) {
                    continue;
                }

                if (i < firstVisibleScrollIdx) {
                    continue;
                }

                if ((!wasFullDrawing && !cell.isDirty) ||
                // Groups are supposed to be drawn after general rows.
                row.isGroupRow) {
                    if (row.isGroupRow) {
                        const obj = { x: cellX, w: cellW, index: curColumn.PRIVATE.index, value: curColumn.ShowTotal ? 0 : null };
                        groupRowIndicesToDrawSet[i] = groupRowIndicesToDrawSet[i] || {};
                        groupRowIndicesToDrawSet[i][j] = obj;
                        totalGroupRow = groupRowIndicesToDrawSet[i];
                    }

                    curY += increaserByY;
                    continue;
                }

                // #region Cell background

                const rowSelected = this.selectedRowIds.includes(row.id);

                let cellBackColor = this.rowBackColor;
                if (rowSelected) {
                    cellBackColor = this.selectedBackColor;
                }
                //* TODO. Hell starts here.
                else if (isValidString(cell.BackColor)) {
                    cellBackColor = cell.BackColor;
                } else if (row.BackColor !== null) {
                    cellBackColor = row.BackColor;
                } else if (i % 2 === 0) {
                    cellBackColor = this.alternatingRowBackColor;
                }

                if (this.additionalFilter && row.item?.superItemId && !row.item.isSubItem && !rowSelected) // Highlight Super Item (FIFO) #80010
                {
                    cellBackColor = this.highlightSuperItemBackColor;
                }

                cellY = curY;
                let stBg = false;
                let separatedColumnWidth = this.separatedColumnWidth;
                if (this.borderWidth === 0 && cellX === 0) {
                    separatedColumnWidth = this.borderWidth;
                }
                if (!isNullOrUndefined(cell.CustomDrawingHandler)) {
                    stBg = cell.CustomDrawingHandler(context, cell, cellX + separatedColumnWidth, cellY, cellW - separatedColumnWidth, cellH);
                }

                // cell background
                if (!stBg) {
                    context.fillStyle = cellBackColor;
                    context.fillRect(cellX + separatedColumnWidth, cellY, cellW - separatedColumnWidth, cellH);
                }
                // #endregion

                if (this.enableRowHover && this.hoveredRow?.id === row.id && !rowSelected) {
                    context.fillStyle = this.hoveredBackColor;
                    context.fillRect(cellX + separatedColumnWidth, cellY, cellW - separatedColumnWidth, cellH);
                }

                if (curColumn.CanEdit && this.editableRowID == i && this.editableColID == j) {
                    this.editHighlightPen.applySettings(context);
                    context.strokeRect(cellX + 1, cellY, cellW - 2, cellH - 2);
                }

                let canDrawDefault = true;
                // Controls drawing.
                if (!cell.ReadOnly && cell.QuickTableEditingInfo) {
                    canDrawDefault = false;
                    // Link.
                    if (cell.QuickTableEditingInfo.ControlType === DynProperty.LINK && cell.value !== null) {
                        this.DrawLink(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cellFontSize);
                    }
                    if (cell.QuickTableEditingInfo.ControlType === DynProperty.LINK_WITH_TEXT && cell.value !== null) {
                        this.DrawLinkWithText(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cellFontSize);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.CELLBUTTON) {
                        this.DrawCellButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.BOOLEAN && cell.value !== null) {
                        this.DrawCheckBox(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value);
                    } else if ((cell.QuickTableEditingInfo.ControlType === DynProperty.DOUBLE ||
                          cell.QuickTableEditingInfo.ControlType === DynProperty.COMBOBOX ||
                          cell.QuickTableEditingInfo.ControlType === DynProperty.COMBOBOX_COMBOITEM_TIF ||
                          cell.QuickTableEditingInfo.ControlType === DynProperty.ACCOUNT ||
                          cell.QuickTableEditingInfo.ControlType === DynProperty.INSTRUMENT) &&
                        cell.value !== null) {
                        this.DrawNumeric(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY);

                        canDrawDefault = true;
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.INFO_PICTURE && cell.value !== null) {
                        this.DrawInfoPicture(context,
                            null, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.INFO_PICTURE_RIGHT_AND_TEXT && cell.value !== null) {
                        this.DrawInfoPictureAndTextRight(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, rowSelected);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT && cell.value !== null) // #112155
                    {
                        this.DrawDelayedPictureAndTextRight(context, cell, cellX, cellY, cellW, cellH, lastMouseMoveX, lastMouseMoveY, cell.value, rowSelected, cellBackColor);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.CLOSE_BUTTON && cell.value !== null) {
                        if (row.item?.Disabled) // #93127
                        {
                            this.DrawInfoPicture(context,
                                null, cellX, cellY, cellW, cellH,
                                lastMouseMoveX, lastMouseMoveY);
                        } else {
                            this.DrawCloseButton(context,
                                cell, cellX, cellY, cellW, cellH,
                                lastMouseMoveX, lastMouseMoveY);
                        }
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.SELL_ASSET_BUTTON) {
                        this.DrawSellAssetButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.ORDER_BUTTON && cell.value !== null) {
                        this.DrawOrderButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.TRADE_BUTTON && cell.value !== null) {
                        this.DrawTradeButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.ITCHART_ADVANCED && cell.value !== null) {
                        this.DrawITChartAdvancedButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.EXPAND_SUPER_POSITION_BUTTON_AND_TEXT && cell.value !== null) // (FIFO super item) #80010
                    {
                        this.DrawExpandSuperPositionButtonAndText(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, rowSelected, row);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.COMPONENT_OF_SUPER_POSITION_TEXT && cell.value !== null) // (FIFO sub item) #80010
                    {
                        this.DrawComponentOfSuperPositionText(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, rowSelected, row);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.TRADING_CENTRAL_ITEM && cell.value !== null) {
                        this.DrawTradingCentralItem(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, rowSelected, row);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.ALERT_BUTTONS_GROUP && cell.value !== null) {
                        this.DrawAlertButtonsGroup(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            row.item);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.TRUNCATE_TEXT_ADDING_ELLIPSIS && cell.value !== null) {
                        this.DrawEllipsisText(context, cell, cellX, cellY, cellW, cellH);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.SPARKLINE && cell.value !== null) {
                        this.DrawSparkLine(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, row.item);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.SYMBOL_WITH_IMGS && cell.value !== null) {
                        this.DrawSymbolWithImgs(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.value, row.item);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.MARKET_CONSENSUS && cell.value !== null) {
                        this.DrawMarketConsensus(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY,
                            cell.formattedValue, row.item);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.PLACE_BUTTON && cell.value !== null) {
                        this.DrawPlaceButton(context,
                            cell, cellX, cellY, cellW, cellH,
                            lastMouseMoveX, lastMouseMoveY);
                    } else if (cell.QuickTableEditingInfo.ControlType === DynProperty.COLOR && cell.value !== null) {
                        this.DrawColor(context, cell, cellX, cellY, cellW, cellH);
                    }
                } else if (cell.ReadOnly && cell.QuickTableEditingInfo) {
                    canDrawDefault = cell.QuickTableEditingInfo.ControlType !== DynProperty.BOOLEAN;
                }
                // Default drawing.
                if (!isNullOrUndefined((cell as InstrumentLookupAutoCompleteQuickTableCell).Draw)) {
                    (cell as InstrumentLookupAutoCompleteQuickTableCell).Draw(context, cellX, cellY, cellW, cellH);
                    canDrawDefault = false;
                }
                if (canDrawDefault) {
                    let foreColor = i % 2 === 0 ? this.alternatingRowForeColor : this.ForeColor;

                    if (rowSelected) {
                        foreColor = this.selectedForeColor;
                    } else if (this.enableRowHover && this.hoveredRow?.id === row.id && !isNullOrUndefined(this.hoveredForeColor)) {
                        foreColor = this.hoveredForeColor;
                    } else if (isValidString(cell.ForeColor)) {
                        foreColor = cell.ForeColor;
                    } else if (row.ForeColor !== null) {
                        foreColor = row.ForeColor;
                    } else if (curColumn.columnType === QuickTableColumnType.SYMBOL || curColumn.columnType === QuickTableColumnType.QUANTITY) {
                        foreColor = ThemeManager.CurrentTheme.TableGoldColor;
                    }

                    const eventArgs = new QuickTableDrawingCellEventArgs(
                        this,
                        row,
                        curColumn.PRIVATE.index,
                        cell,
                        this.cellFont,
                        foreColor,
                        cellBackColor,
                        cellX,
                        cellY,
                        cellW,
                        cellH);

                    this.OnDrawCell.Raise(context, eventArgs);
                    const isHandled: boolean = eventArgs.handled;
                    if (!isHandled) {
                        if (isValidString(cell.textPattern)) {
                            const patternW = QuickTableUtils.GetWidth(context, cell.textPattern) + 3;
                            const indexT = cell.formattedValue.toLowerCase().indexOf(cell.textPattern.toLowerCase());
                            if (indexT !== -1) {
                                const textOffset = QuickTableUtils.GetWidth(context, cell.formattedValue.substring(0, indexT));
                                context.fillStyle = ThemeManager.CurrentTheme.qtTextPatternColor;
                                context.fillRect(cellX + 5 + textOffset, cellY + 2, patternW, cellH - 5);
                            }
                        }

                        // cell data
                        context.fillStyle = foreColor;

                        if (row.item?.Disabled) {
                            context.fillStyle = ThemeManager.CurrentTheme.qtDisabledRowTextColor;
                        }

                        const xPadding = cell.xPadding || 5;
                        if (!this.isVertical) {
                            this.tableResizer.setColumnMinSize(curColumn.PRIVATE.index, cell.formattedValue.length * 6.2 + xPadding + 8);
                        }
                        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX, cellY, cellW, cellH, xPadding, 3, cell.TruncateTextOverflowAddingEllipsis, cellBackColor);
                    }
                }

                // reset flag
                cell.isDirty = false;
                curY += increaserByY;
            }

            if (this.ShowTotals) {
                context.fillStyle = this.totalsBackColor;
                context.fillRect(0 + 1, this.height - cellH, this.width, cellH);

                if (curColumn.ShowTotal) {
                    let val = '';
                    if (rowK0?.item) {
                        val = rowK0.item.getFormatTotalValue(curColumn.PRIVATE.index, summTotalValue, false, this.totalCurrency);
                    }

                    context.fillStyle = this.totalsForeColor;
                    QuickTableUtils.DrawTextInRect(context, val, cellX, this.height - cellH, cellW, cellH, 5, 3);
                }
            }

            curX += curColumn.PRIVATE.drawingWidth;
            context.restore();
        }

        if (this.enableRowHoverBorder &&
            !isNullOrUndefined(this.hoveredRow) &&
            !this.selectedRowIds.includes(this.hoveredRow.id) &&
            !this.hoveredRow.isGroupRow) {
            const rowRectangle = this.hoveredRow.displayRectangle;
            context.strokeStyle = this.hoveredBorderColor;
            const scrollWidth = this.scroll.Visible ? Scroll.SCROLL_WIDTH : 0;
            context.strokeRect(rowRectangle.X + this.borderWidth + 0.5, rowRectangle.Y + 0.5, rowRectangle.Width - 2 * this.borderWidth - 1 - scrollWidth, rowRectangle.Height - 1); // -1 px because we add 0.5 px
        }

        // Columns vertical lines (draw after rows count analyse)
        context.beginPath();
        context.strokeStyle = this.gridColor;

        // var rowsArray = this.rowsArray;
        // var rowHeight = this.rowHeight;
        // var startCurY = this.showHeader ? rowHeight : this.borderWidth;

        // for (var i = 0; i < colLen; i++)
        // {
        //     var curColumn = this.sortedColumns[i];
        //     if (!curColumn.visible || curColumn.hidden)
        //         continue;

        //     var colDrawingX = curColumn.PRIVATE.drawingX;
        //     var curY = startCurY;

        //     for (var row_i = firstVisibleScrollIdx; row_i < rowLen; row_i++)
        //     {
        //         if (curY > ClientH - curTotal) break;

        //         var row = rowsArray[row_i];
        //         if (!row.visible) continue;

        //         if (this.gridVerticalVisible && !row.isGroupRow)
        //         {
        //             context.moveTo(colDrawingX, curY);
        //             context.lineTo(colDrawingX, curY + rowHeight);      // vertical line before each column

        //             if (!i)
        //             {
        //                 context.moveTo(colDrawingX + ClientW - 1, curY);
        //                 context.lineTo(colDrawingX + ClientW - 1, curY + rowHeight);    // vertical line after last column
        //             }
        //         }

        //         if (this.gridHorizontalVisible && !row.isGroupRow)
        //         {
        //             context.moveTo(colDrawingX, curY + rowHeight);
        //             context.lineTo(colDrawingX + ClientW, curY + rowHeight);    // horizontal line after each row

        //             if (!row_i)
        //             {
        //                 context.moveTo(colDrawingX, curY);
        //                 context.lineTo(colDrawingX + ClientW, curY);            // horizontal line before first row
        //             }
        //         }

        //         curY += rowHeight;
        //     }
        // }

        // if (this.gridHorizontalVisible || this.gridVerticalVisible)
        //     context.stroke();

        this.DrawGroupRows(groupRowIndicesToDrawSet, wasFullDrawing, lastMouseMoveX, lastMouseMoveY);

        // Right vertical line
        // context.moveTo(ClientW - 1, 0);
        // context.lineTo(ClientW - 1, ClientH);

        // Bottom rows horizontal line
        // if (hasAnyRow)
        // {
        //     context.moveTo(0, curY);
        //     context.lineTo(ClientW, curY);
        // }

        // // Top horizontal line0
        // context.moveTo(0, 0);
        // context.lineTo(ClientW, 0);

        // // Horizontal header bottom line.
        // if (this.showHeader)
        // {
        //     context.moveTo(0, this.rowHeight);
        //     context.lineTo(ClientW, this.rowHeight);
        // }

        // // Bottom horizontal line
        // context.moveTo(0, ClientH - this.borderWidth);
        // context.lineTo(ClientW, ClientH - this.borderWidth);
        // context.stroke();

        // Column resizing indicator
        if (this.movingType === QuickTableMovingType.ColumnResize && this.activeColumnIndex !== -1) {
            context.save();

            context.beginPath();
            context.strokeStyle = this.columnSliderColor;
            context.lineWidth = 1.5;
            context.setLineDash([5.5, 2.5]);
            const xCoord = this.sortedColumns[this.activeColumnIndex].PRIVATE.drawingX + this.sortedColumns[this.activeColumnIndex].PRIVATE.drawingWidth;
            context.moveTo(xCoord, 1);
            context.lineTo(xCoord, ClientH);
            context.stroke();

            context.restore();
        }

        context.restore();

        if (this.movingType === QuickTableMovingType.ColumnMoving && this.activeColumnIndex !== -1) {
            context.save();
            let lastMouseOverColumnIndex = -1;
            if (this.lastMouseMoveHitTestInfo != null) {
                lastMouseOverColumnIndex = this.lastMouseMoveHitTestInfo.columnIndex;
            }

            const greyP = this.dragDefaultPen;
            const p = this.dragHighlightPen;

            const curBackBrush = this.headerBackBrush;
            const activeColumn = this.sortedColumns[this.activeColumnIndex];
            context.FillRect(curBackBrush, this.lastMouseMove.X + this.verticalScrollClickDelta, 1, activeColumn.PRIVATE.drawingWidth, this.rowHeight);
            context.DrawRect((lastMouseOverColumnIndex !== this.activeColumnIndex ? p : greyP), this.lastMouseMove.X + this.verticalScrollClickDelta, 1, activeColumn.PRIVATE.drawingWidth, this.rowHeight);

            const curF = this.HeaderFont;
            // if (columns[this.activeColumnIndex].HeaderFont != null)
            // curF = this.columns[this.activeColumnIndex].HeaderFont;

            const curBrush = this.headerTextColorBrush;
            // if (columns[activeColumnIndex].headerForeColorBrush != null)
            //    curBrush = _columns[activeColumnIndex].headerForeColorBrush;

            const Xt = this.lastMouseMove.X + this.verticalScrollClickDelta + activeColumn.PRIVATE.drawingWidth / 2;

            context.DrawString(activeColumn.headerText, curF, curBrush, Xt, 6, 'center', 'top');

            if (lastMouseOverColumnIndex !== -1 && lastMouseOverColumnIndex !== this.activeColumnIndex) {
                const lastOverColumn = this.sortedColumns[lastMouseOverColumnIndex];
                const w = lastOverColumn.PRIVATE.drawingWidth;
                const x = lastOverColumn.PRIVATE.drawingX;
                const r = w + x;
                let lineX = r;

                if (lastOverColumn.displayedIndex - activeColumn.displayedIndex === 1) {
                    lineX = r;
                } else if (lastOverColumn.displayedIndex - activeColumn.displayedIndex === -1) {
                    lineX = x;
                } else if (lastMouseOverColumnIndex > -1 && r - this.lastMouseMove.X > w / 2) {
                    lineX = x;
                }

                if (lineX === 0) {
                    lineX = 1;
                }

                context.DrawLine(p, lineX, this.rowHeight, lineX, this.height);
            }
            context.restore();
        }

        this.scroll.Draw();

        this.needRedrawBackground = false;
    }

    public DrawGroupRows (
        groupRowIndicesSet,
        wasFullDrawing,
        lastMouseMoveX: number,
        lastMouseMoveY: number): void {
        if (isNullOrUndefined(groupRowIndicesSet)) return;

        const rowsArray = this.rowsArray;
        if (isNullOrUndefined(rowsArray)) return;

        const context = this.context;
        const groupTextColor = ThemeManager.CurrentTheme.LinkColor;

        const canUserCollapseGroups = this.canUserCollapseGroups;

        let rowK0 = null;
        if (this.ShowTotals) {
            const keys = Object.keys(this.rows);
            rowK0 = this.rows[keys[0]];
        }

        for (const rowIdx in groupRowIndicesSet) {
            const row: QuickTableGroupRow<ItemType> = rowsArray[rowIdx];
            if (isNullOrUndefined(row) || (!row.isDirty && !wasFullDrawing)) {
                continue;
            }

            const groupRowRect = row.displayRectangle;
            const grX = groupRowRect.X;
            const grY = groupRowRect.Y;
            const grW = groupRowRect.Width;
            const grH = groupRowRect.Height;

            // I'm not using context.createPattern() here as it's buggy.
            const rowColor = canUserCollapseGroups && groupRowRect.Contains(lastMouseMoveX, lastMouseMoveY) ? this.tableGroupRowHoveredBrush : this.tableGroupRowDefaultBrush;
            context.FillRect(rowColor, grX, grY, grW, grH);

            if (canUserCollapseGroups) {
                context.drawImage(row.collapsed ? this.tableGroupClosedImg : this.tableGroupOpenImg, grX + 4.5, grY + 1.5);
            }

            context.fillStyle = groupTextColor;
            let fillText = row.GroupValue;
            if (!isNullOrUndefined(this.FormatGroup)) {
                fillText = this.FormatGroup(fillText);
            }

            context.textAlign = 'start';
            context.fillText(fillText, grX + 21, grY + 5);

            if (isValidString(row.infoIconTooltip)) {
                this.DrawInfoPicture(context, row.infoIconTooltip, grX + grW - 30, grY + 3, 17, 17, lastMouseMoveX, lastMouseMoveY);
            }

            if (this.ShowTotals) {
                let cellX = 0;
                let cellW = 0;
                let columIndex = null;
                let columValue = null;
                const groupTextEndX = QuickTableUtils.GetWidth(context, fillText) + grX + 21;
                for (const columIdx in groupRowIndicesSet[rowIdx]) {
                    const columnDataSet = groupRowIndicesSet[rowIdx][columIdx];
                    cellX = columnDataSet.x;
                    cellW = columnDataSet.w;
                    columIndex = columnDataSet.index;
                    columValue = columnDataSet.value;
                    if (columValue !== null && cellX !== 0) {
                        context.textAlign = 'end'; // перенес в цикл, так как textAlign может поменяться в DrawTextInRect но это не должно влиять на последующие колонки тоталов
                        let val = '';
                        if (!isNullOrUndefined(rowK0?.item)) {
                            val = rowK0.item.getFormatTotalValue(columIndex, columValue);
                        }

                        context.fillStyle = groupTextColor;

                        if (cellX < groupTextEndX && // #87929 первая строчка более слабое условия, чтоб лишний раз не вызывать GetTextStartX TODO подумать над облегчением метода GetTextStartX имеющий схожий подсчет с DrawTextInRect
                        QuickTableUtils.GetTextStartX(context, val, cellX, grY, cellW, grH, 5, 3) < groupTextEndX) { continue; }

                        QuickTableUtils.DrawTextInRect(context, val, cellX, grY, cellW, grH, 5, 3);
                        context.FillRect(rowColor, cellX + cellW, grY, grW - cellX - cellW, grH);
                    }
                }
            }
            row.isDirty = this.ShowTotals;
        }
    }

    // #region Custom Cell Controls Drawing

    public DrawLink (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number,
        cellFontSize: number): void {
        context.fillStyle = ThemeManager.CurrentTheme.LinkColor;
        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX, cellY, cellW, cellH, 5, 2);

        if (isValidString(cell.formattedValue) && QuickTable.isCellControlHovered(
            cellX, cellY, cellW, cellH,
            lastMouseMoveX, lastMouseMoveY)) {
            const cellTextW = context.measureText(cell.formattedValue).width;
            const linkLineY = cellY + 4 + cellFontSize;
            context.beginPath();
            context.strokeStyle = ThemeManager.CurrentTheme.LinkColor;
            context.moveTo(cellX + 5, linkLineY);
            context.lineTo(cellX + cellTextW, linkLineY);
            context.stroke();
        }
    }

    public DrawLinkWithText (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number,
        cellFontSize: number): void {
        context.fillStyle = this.ForeColor;
        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX, cellY, cellW, cellH, 5, 2);

        context.fillStyle = ThemeManager.CurrentTheme.LinkColor;
        const cellOffset = context.measureText(cell.formattedValue).width + 15;
        const linkText = this.LinkText;
        QuickTableUtils.DrawTextInRect(context, linkText, cellX + cellOffset, cellY, cellW, cellH, 5, 2);

        if (isValidString(cell.formattedValue) && QuickTable.isCellControlHovered(
            cellX, cellY, cellW, cellH,
            lastMouseMoveX, lastMouseMoveY)) {
            // const cellTextW = context.measureText(cell.formattedValue).width;
            const linkTextW = context.measureText(linkText).width;
            const linkLineY = cellY + 4 + cellFontSize;
            context.beginPath();
            context.strokeStyle = ThemeManager.CurrentTheme.LinkColor;
            context.moveTo(cellX + 5 + cellOffset, linkLineY);
            context.lineTo(cellX + 5 + linkTextW + cellOffset, linkLineY);
            context.stroke();
        }
    }

    // TODO. It's only a remove styled button for now.
    public DrawCellButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number): void {
        const image = QuickTable.isCellControlHovered(
            cellX, cellY, cellW, cellH,
            lastMouseMoveX, lastMouseMoveY)
            ? ThemeManager.CurrentTheme.cellRemoveHoverImage
            : ThemeManager.CurrentTheme.cellRemoveImage;

        context.drawImage(image,
            Math.round(cellX + cellW / 2 - 8) - 0.5,
            Math.round(cellY + cellH / 2 - 8) - 0.5);
    }

    public DrawCheckBox (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number,
        value): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const hover = QuickTable.isCellControlHovered(
            cellX, cellY, cellW, cellH,
            lastMouseMoveX, lastMouseMoveY);

        if (value) {
            image = hover
                ? theme.checkBoxCheckedHoverImg
                : theme.checkBoxCheckedImg;
        } else {
            image = hover
                ? theme.checkBoxUncheckedHoverImg
                : theme.checkBoxUncheckedImg;
        }

        context.drawImage(image,
            Math.round(cellX + cellW / 2 - 7) - 0.5,
            Math.round(cellY + cellH / 2 - 7.5) - 0.5);
    }

    public DrawNumeric (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number): void {
        if (!QuickTable.isCellControlHovered(cellX, cellY, cellW, cellH, lastMouseMoveX, lastMouseMoveY) ||
            !cell.QuickTableEditingInfo.IsEditable) {
            return;
        }

        context.save();
        context.lineWidth = 1;
        context.strokeStyle = ThemeManager.CurrentTheme.LinkColor;
        context.setLineDash([3, 2]);
        context.beginPath();
        context.rect(Math.round(cellX + 2), cellY + 1, Math.round(cellW - 4), cellH - 2);
        context.stroke();
        context.restore();
    }

    public DrawInfoPicture (context: CanvasRenderingContext2D, tooltip: string | null, cellX: number, cellY: number, cellW: number, cellH: number, lastMouseMoveX: number, lastMouseMoveY: number): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);
        image = hover ? theme.i_icon_hover : theme.i_icon_default;

        context.drawImage(image, x, y);

        if (hover && tooltip?.length > 0) {
            this.OnShowTooltip.Raise(tooltip);
        }
    }

    public DrawInfoPictureAndTextRight (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, rowSelected: boolean): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW - 7 - 17) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        // var hoverfull = QuickTable.isCellControlHovered(cellX, cellY, cellW, cellH, lastMouseMoveX, lastMouseMoveY);

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);
        image = hover ? theme.i_icon_hover : theme.i_icon_default;

        let foreColor = this.ForeColor;
        // Forecolor for selected row
        if (rowSelected) {
            foreColor = this.selectedForeColor;
        } else if (isValidString(cell.ForeColor)) {
            foreColor = cell.ForeColor;
        }
        // else if (row.ForeColor !== null) // no arg such as 'row' in this method
        //     foreColor = row.ForeColor;

        context.fillStyle = foreColor;
        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX, cellY, cellW, cellH, 5, 3);

        // if (hoverfull)   // пока убрал, поскольку INFO_PICTURE_RIGHT_AND_TEXT нигде не используется, если потребуется где-то еще кроме MarginOEControl можно протянуть аргументом флажок отображения (по ховеру или отображать всегда)
        context.drawImage(image, x, y);

        if (hover) {
            const data = cell.QuickTableEditingInfo?.GetDataHandler ? cell.QuickTableEditingInfo.GetDataHandler() : null;
            if (!isNullOrUndefined(data)) {
                this.OnShowTooltip.Raise(data);
            }
        }
    }

    public DrawDelayedPictureAndTextRight (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, rowSelected: boolean, cellBackColor): void// formatedValue, rowSelected)
    {
        const theme = ThemeManager.CurrentTheme;
        const image = theme.delayed_icon_default;

        const x = Math.round(cellX + cellW - 7 - 17) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);

        let foreColor = this.ForeColor; // Forecolor for selected row
        if (rowSelected) {
            foreColor = this.selectedForeColor;
        } else if (cell.ForeColor) {
            foreColor = cell.ForeColor;
        }

        context.fillStyle = foreColor;
        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX, cellY, cellW, cellH, 5, 3);

        context.FillRect(new SolidBrush(cellBackColor), x, y, 35, 17);
        context.drawImage(image, x + 2, y);

        if (hover) {
            const data = cell.QuickTableEditingInfo?.GetDataHandler ? cell.QuickTableEditingInfo.GetDataHandler() : null;
            if (!isNullOrUndefined(data)) {
                this.OnShowTooltip.Raise(data);
            }
        }
    }

    public DrawCloseButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);
        image = hover ? theme.close_hover : theme.close_default;

        context.drawImage(image, x, y);
    }

    public DrawPlaceButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);
        image = hover ? theme.place_hover : theme.place_default;

        context.drawImage(image, x, y);
    }

    public DrawSellAssetButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);
        image = hover ? theme.order_hover : theme.order_default;

        context.drawImage(image, x, y);
    }

    public DrawOrderButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);

        if (formatedValue.status === InterTraderBtnStatus.Open) {
            image = hover ? theme.order_hover : theme.order_default;
        } else {
            image = theme.order_closed;
        }

        if (hover && formatedValue.tooltip) {
            this.OnShowTooltip.Raise(formatedValue.tooltip);
        } // пока так, потом исправим общим механизмом тултипов в таблицах

        context.drawImage(image, x, y);
    }

    public DrawTradeButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);

        if (formatedValue.status === InterTraderBtnStatus.Open) {
            image = hover ? theme.trade_hover : theme.trade_default;
        } else {
            image = theme.trade_closed;
        }

        if (hover && formatedValue.tooltip) {
            this.OnShowTooltip.Raise(formatedValue.tooltip);
        } // пока так, потом исправим общим механизмом тултипов в таблицах

        context.drawImage(image, x, y);
    }

    public DrawITChartAdvancedButton (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue): void {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        const x = Math.round(cellX + cellW / 2 - 7) - 0.5;
        const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, 17, 17, lastMouseMoveX, lastMouseMoveY);

        image = hover ? theme.advanced_chart_hover : theme.advanced_chart_default;

        context.drawImage(image, x, y);
    }

    public DrawAlertButtonsGroup (context: CanvasRenderingContext2D, cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number, lastMouseMoveX: number, lastMouseMoveY: number, alertItem): void {
        const scrollWidth = this.scroll.Visible ? 4 : 0;

        const x1_image = Math.round(cellX + cellW - 64) - 0.5 - scrollWidth;
        const x2_image = x1_image + 20;
        const x3_image = x2_image + 20;
        const y_image = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

        const isHoveredPlayImage = QuickTable.isCellControlHovered(x1_image, y_image, 17, 17, lastMouseMoveX, lastMouseMoveY);
        const isHoveredUpdateImage = QuickTable.isCellControlHovered(x2_image, y_image, 17, 17, lastMouseMoveX, lastMouseMoveY);
        const isHoveredCloseImage = QuickTable.isCellControlHovered(x3_image, y_image, 17, 17, lastMouseMoveX, lastMouseMoveY);

        const playImage = alertItem.GetImage(AlertSubImage.PLAY_STOP, isHoveredPlayImage);
        const updateImage = alertItem.GetImage(AlertSubImage.UPDATE, isHoveredUpdateImage);
        const closeImage = alertItem.GetImage(AlertSubImage.CLOSE, isHoveredCloseImage);

        context.drawImage(playImage, x1_image, y_image);
        context.drawImage(updateImage, x2_image, y_image);
        context.drawImage(closeImage, x3_image, y_image);
    }

    public DrawEllipsisText (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number): void {
        let text = cell.formattedValue;
        const count = Math.floor(cellW / 5.8 - 3);

        if (count < text.length) {
            text = count > 0 ? `${cell.formattedValue.substring(0, count)}...` : '';
        }

        context.fillStyle = this.ForeColor;
        QuickTableUtils.DrawTextInRect(context, text, cellX, cellY, cellW, cellH, 5, 3);
    };

    public DrawExpandSuperPositionButtonAndText (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, rowSelected: boolean, row: QuickTableRow<ItemType>) {
        const theme = ThemeManager.CurrentTheme;
        let image = null;

        let offsetX = 7; const offsetY = 6; const imgSize = 11;

        const x = Math.round(cellX + offsetX) - 0.5;
        const y = Math.round(cellY + cellH / 2 - offsetY) - 0.5;

        const hover = QuickTable.isCellControlHovered(x, y, imgSize, imgSize, lastMouseMoveX, lastMouseMoveY);
        let imgName = row.collapsedFIFO ? 'minus' : 'plus';
        imgName += hover ? '_hover' : '_default';
        image = theme[imgName];
        if (this.additionalFilter) {
            context.drawImage(image, x, y);
        } else {
            offsetX -= imgSize + offsetX;
        }

        let foreColor = this.ForeColor;
        // Forecolor for selected row
        if (rowSelected) {
            foreColor = this.selectedForeColor;
        } else if (this.enableRowHover && this.hoveredRow?.id === row.id && !isNullOrUndefined(this.hoveredForeColor)) {
            foreColor = this.hoveredForeColor;
        } else if (cell.ForeColor) {
            foreColor = cell.ForeColor;
        } else if (row.ForeColor !== null) {
            foreColor = row.ForeColor;
        }

        context.fillStyle = foreColor;

        if (row?.item?.Disabled) // #93127
        {
            context.fillStyle = ThemeManager.CurrentTheme.qtDisabledRowTextColor;
        }

        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX + offsetX + imgSize, cellY + 2, cellW, cellH, 5, 3);
    }

    public DrawComponentOfSuperPositionText (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, rowSelected: boolean, row: QuickTableRow<ItemType>): void {
        const offsetX = 19;
        // const offsetY = 5;

        let foreColor = this.ForeColor;
        // Forecolor for selected row
        if (rowSelected) {
            foreColor = this.selectedForeColor;
        } else if (this.enableRowHover && this.hoveredRow?.id === row.id && !isNullOrUndefined(this.hoveredForeColor)) {
            foreColor = this.hoveredForeColor;
        } else if (cell.ForeColor) {
            foreColor = cell.ForeColor;
        } else if (row.ForeColor !== null) {
            foreColor = row.ForeColor;
        }

        if (row?.item?.Disabled) // #93127
        {
            foreColor = ThemeManager.CurrentTheme.qtDisabledRowTextColor;
        }

        context.fillStyle = foreColor;
        QuickTableUtils.DrawTextInRect(context, cell.formattedValue, cellX + offsetX, cellY + 2, cellW, cellH, 5, 3);
    }

    public DrawTradingCentralItem (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, rowSelected, row: QuickTableRow<ItemType>): void {
        const theme = ThemeManager.CurrentTheme;

        const item: any = row.item;
        const valueName = item.valueName;
        const value = item.value;

        const offsetX = 5;
        let x = cellX + offsetX;
        let y = cellY + 2;

        context.fillStyle = theme.TableGoldColor;
        if (item.valueNameVisible) // if is not line break row
        {
            QuickTableUtils.DrawTextInRect(context, valueName, x, y, cellW, cellH, 5, 3);
        }

        x += item.offsetByValueName;

        const imgName = item.imgName;

        if (imgName) {
            const img = theme[imgName];
            y += img.height / 2 + (imgName === 'neutral' ? 4.5 : 1); // image alignment
            context.drawImage(img, x + img.width, y);
        } else {
            context.fillStyle = this.ForeColor;
            QuickTableUtils.DrawTextInRect(context, value, x, y, cellW, cellH, 5, 3);
        }
    }

    public DrawSparkLine (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, item): void {
        const arr = formatedValue;
        const len = Math.min(item.sparkLinePointNum, arr.length);
        const startI = arr.length - len;
        const backupOldStrokeStyle = context.strokeStyle; // сохраним стиль пера чтобы не вызывать ресурсоёмкие save/restore

        context.strokeStyle = cell.ForeColor || Color.White;
        context.beginPath(); // Начинает новый путь

        const offsetX = 8;
        const dx = (cellW - offsetX * 2) / (len - 1);
        let x = cellX + offsetX;

        for (let i = 0; i < len; i++) {
            const y = cellY + 0.5 * cellH * (1 - arr[i + startI] / 100); // допустимые значения от -100% до 100%

            context[i ? 'lineTo' : 'moveTo'](x, y); // если нулевая точка, то передвигаем перо в начальную точку, иначе рисуем линию до очередной точки
            x += dx;
        }

        context.stroke(); // Отображает путь
        context.strokeStyle = backupOldStrokeStyle; // возвращаем прежний стиль пера
    }

    public DrawSymbolWithImgs (context: CanvasRenderingContext2D,
        cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number,
        lastMouseMoveX: number, lastMouseMoveY: number, formatedValue, item): void {
        const theme = ThemeManager.CurrentTheme;

        const circleOffsetX = 7; const circleR = 4;
        let offsetX = 21; const offsetY = 5;
        const backupOldStrokeStyle = context.strokeStyle; // сохраним стиль пера чтобы не вызывать ресурсоёмкие save/restore

        context.strokeStyle = item.insColor; // готовим к отрисовке кружочка
        context.fillStyle = item.insColor;
        context.beginPath();
        context.arc(cellX + circleOffsetX + circleR, cellY + cellH / 2, circleR, 0, 2 * Math.PI, false);
        context.fill(); // Отображает круг

        context.strokeStyle = backupOldStrokeStyle; // возвращаем прежний стиль пера

        if (item.showLogos) {
            const img = item.logoIMG;
            if (img) {
                context.drawImage(img, cellX + offsetX, cellY + offsetY);
                offsetX += img.width + 5;
            }
        }

        context.fillStyle = item.GetCellForeColorMap(0) || theme.TableGoldColor;
        QuickTableUtils.DrawTextInRect(context, formatedValue, cellX + offsetX, cellY + offsetY, cellW, cellH);
    }

    public DrawMarketConsensus (context: CanvasRenderingContext2D, cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number, lastMouseMoveX: number, lastMouseMoveY: number, formattedValue, item): void {
        if (formattedValue == null) { return; }

        const widthCell = Math.max(cellW, 100);
        const borderRadius = 2;
        const offsetX = 10;
        const textOffsetY = 5;
        const buttonSize = 20;
        const xButton = cellX + cellW - buttonSize - offsetX / 2;
        const rectangleX = cellX + offsetX;
        const rectangleOffsetY = 2;
        const dx = widthCell - 2 * offsetX - buttonSize;
        const rectangleW = (formattedValue.Value / 100) * dx;

        const percentText = `${formattedValue.Value}%`;
        const percentTextWidth = QuickTableUtils.GetWidth(context, percentText) + 6;
        const percentTextX = Math.max(xButton - percentTextWidth, offsetX);

        const barRect = new Rectangle(rectangleX, cellY + rectangleOffsetY, rectangleW, cellH - 2 * rectangleOffsetY);
        const percentRect = new Rectangle(percentTextX, cellY + textOffsetY, xButton - percentTextX, cellH - 2 * textOffsetY);
        const textRect = new Rectangle(barRect.Left() + 3, barRect.Top() + 1, percentRect.X - barRect.Left() - 3, barRect.Height);

        // Draw rounded rectangle for the bar
        context.SimpleRoundRectangle(barRect.X, barRect.Y, barRect.Width, barRect.Height, borderRadius, new Pen(formattedValue.Color), new SolidBrush(formattedValue.Color));

        // Draw instrument text
        context.DrawStringInRect(formattedValue.Name, this.cellFontObj, new SolidBrush(ThemeManager.CurrentTheme.TableGoldColor), textRect, true, 'left', 'middle');

        // Draw Expert Choice button (if applicable)
        if (formattedValue.IsExpertChoice === true) {
            const yButton = cellY + (cellH - buttonSize) / 2;
            const hover = QuickTable.isCellControlHovered(xButton, yButton, buttonSize, buttonSize, lastMouseMoveX, lastMouseMoveY);
            const image = ThemeManager.CurrentTheme.expert_choice;

            context.drawImage(image, xButton, yButton);
            if (hover && formattedValue.tooltip != null) {
                this.OnShowTooltip.Raise(formattedValue.tooltip);
            }
        }

        // Draw percent text
        context.DrawStringInRect(percentText, this.cellFontObj, new SolidBrush(ThemeManager.CurrentTheme.quickTableForeColor), percentRect, false, 'center', 'middle');
    }

    public DrawColor (context: CanvasRenderingContext2D, cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number): void {
        const lineWidth = Math.max(1, cellW - 10);
        const xOffset = Math.max(1, cellW - lineWidth);
        const yOffset = cellH / 2 - 3;
        const linePen = new Pen(cell.value, 3);
        context.DrawLine(linePen, cellX + xOffset, cellY + yOffset, cellX + cellW - xOffset, cellY + yOffset);
    }

    // QuickTable.isCellImageHovered  (
    //    cellX, cellY, cellW, cellH,
    //    lastMouseMoveX, lastMouseMoveY)
    // {
    //    return cellX <= lastMouseMoveX && lastMouseMoveX < cellX + cellW &&
    //        cellY <= lastMouseMoveY && lastMouseMoveY < cellY + cellH;
    // };

    public static isCellControlHovered (cellX: number, cellY: number, cellW: number, cellH: number, lastMouseMoveX: number, lastMouseMoveY: number): boolean {
        return cellX <= lastMouseMoveX && lastMouseMoveX < cellX + cellW &&
        cellY <= lastMouseMoveY && lastMouseMoveY < cellY + cellH;
    }

    // #endregion

    public setShowColumnHeaders (show: boolean): void {
        if (this.showHeader === show) {
            return;
        }

        this.showHeader = show;

        this.scroll.setBounds(this.getScrollRect());
    }

    public onResizeBegin (): void {
    // Skip drawing during resize
        this.resizingNow = true;

        /// /
        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public onResizeEnd (): void {
        this.resizingNow = false;

        //
        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    // Sort columns
    public UpdateSortedColumns (): void {
        for (let i = 0; i < this.columns.length; i++) {
            this.columns[i].PRIVATE.index = i;
        }

        this.sortedColumns = [];
        this.sortedColumns = this.sortedColumns.concat(this.columns);

        this.sortedColumns.sort(QuickTable.updateSortedColumnsComparator);

        this.updateLastVisibleSortedColumnIdx();
    }

    public updateLastVisibleSortedColumnIdx (): void {
        let lastVisibleSortedColumnIdx = -1;

        const sortedColumns = this.sortedColumns;
        let i = sortedColumns.length;
        while (i--) {
            const column = sortedColumns[i];
            if (!column.visible || column.hidden) {
                continue;
            }

            lastVisibleSortedColumnIdx = i;
            break;
        }

        this.lastVisibleSortedColumnIdx = lastVisibleSortedColumnIdx;
    }

    public setAllRowsVisibility (visible: boolean): void {
        const flag = this.additionalFilter;
        this.rowsArray.forEach(function (row) {
            if (!row.item?.isSubItem || flag) {
                row.visible = visible;
                row.collapsedFIFO = true;
            }
        });
        this.rowCountChanged = true;
        this.needRedrawBackground = true;
    }

    public filterFIFOByAccountNumber (row: QuickTableRow<ItemType>, collapsed: boolean, flag: boolean): boolean {
        if (row.item.superItemId) {
            if (row.item.isSubItem && (!collapsed || !flag)) // sub item && needUpdate
            {
                row.visible = false;
            } // apply visible change
            else {
                collapsed = row.collapsedFIFO;
            } // remember supers' state
        }

        return collapsed;
    }

    public filterByAccountNumber (acctNumber: string = null): void {
        if (acctNumber == null && LinkedSystem.accLinkingActive) {
            acctNumber = LinkedSystem.getAccount();
        }
        const flag = this.additionalFilter;
        let collapsed = true;
        this.totalCurrency = null;

        this.rowsArray.forEach((row) => {
            const item = row.item;
            if (item != null) {
                const rowAcc = item.GetCurrentAccount();
                if (rowAcc != null) {
                    row.visible = rowAcc.AcctNumber === acctNumber || acctNumber == null; // update row visibility if filter by account exist
                }

                if (row.visible) {
                    collapsed = this.filterFIFOByAccountNumber(row, collapsed, flag);
                    this.totalCurrency = item.updateTotalCurrencyWith(this.totalCurrency); // обновляем валюту отображаемую в тотале табл. #110253
                }
            }
        });

        this.updateGroupRowVisibility();

        this.rowCountChanged = true;
        this.needRedrawBackground = true;
    }

    public updateGroupRowVisibility (): void {
        Object.values(this.groupStorageDict).forEach((groupItem: QTGroupStorageItem) => {
            const hasVisibleRow = groupItem.groupRowsArray.some(row => row.visible);
            groupItem.groupHeaderRow.visible = hasVisibleRow;
        });
    }

    public visibleRowCount (): number {
        let num = 0;
        this.rowsArray.forEach(function (row) {
            if (row.visible && !row.isGroupRow) {
                num++;
            }
        });
        return num;
    }

    public static updateSortedColumnsComparator (col1, col2): 1 | -1 {
        const dispIdx1 = col1.displayedIndex;
        const dispIdx2 = col2.displayedIndex;

        if (dispIdx1 === dispIdx2) {
            return col1.sortIndex > col2.sortIndex ? 1 : -1;
        } else {
            return dispIdx1 > dispIdx2 ? 1 : -1;
        }
    }

    public ClearAll (): void {
        this.ClearRows();
    }

    public ClearRows (): void {
        this.rowsArray.forEach(function (row) { row.Dispose(); });

        this.rows = {};
        this.rowsArray = [];

        this.selectedRowIds = [];
        this.afterSelectionChanged(true);

        this.clearAllGroupData();

        this.updateScrollElementsCount();
        this.rowCountChanged = true;
    }

    public BeginUpdate (): void {
        this.isUpdatingNow = true;
    }

    public EndUpdate (): void {
        this.isUpdatingNow = false;
        this.endUpdateWork();
    }

    // TODO. Rename.
    public endUpdateWork (): void {
        if (this.groupByColumnIndex !== -1) {
            this.recreateRowsArrayFromGroupStorage();
        }

        this.updateScrollElementsCount();
        this.needRedrawBackground = true;
    }

    public AddRow (row: QuickTableRow<ItemType>): QuickTableRow<ItemType> {
        if (!isNullOrUndefined(this.rows[row.id])) {
            return;
        }

        this.rows[row.id] = row;

        if (this.AddToEnd) {
            this.rowsArray.push(row);
        } else {
            this.rowsArray.splice(0, 0, row);
        }

        if (this.groupByColumnIndex !== -1) {
            this.addRowToGroupStorage(row);
        }

        // В режиме апдейта не делаем лишний раз
        if (!this.isUpdatingNow) {
            this.endUpdateWork();
        }

        this.totalCurrency = null;
        this.rowCountChanged = true;

        return row;
    }

    public InsertRow (row: QuickTableRow<ItemType>, insertIdx: number): void {
        if (!isNullOrUndefined(this.rows[row.id])) {
            return;
        }

        this.rows[row.id] = row;
        this.rowsArray.splice(insertIdx, 0, row);

        if (this.groupByColumnIndex !== -1) {
            this.addRowToGroupStorage(row);
        }

        // В режиме апдейта не делаем лишний раз
        if (!this.isUpdatingNow) {
            this.endUpdateWork();
        }

        this.totalCurrency = null;
        this.rowCountChanged = true;
    }

    public RemoveRow (rowId: any): void {
        const row = this.rows[rowId];
        if (!row) return;

        this.rowsArray.splice($.inArray(row, this.rowsArray), 1);
        delete this.rows[rowId];

        const idx = this.selectedRowIds.indexOf(rowId);
        if (idx !== -1) {
            this.selectedRowIds.splice(idx, 1);
            this.afterSelectionChanged(true);
        }

        if (this.groupByColumnIndex !== -1) {
            this.removeRowFromGroupStorage(row);
        }

        row.Dispose();

        // В режиме апдейта не делаем лишний раз
        if (!this.isUpdatingNow) { this.endUpdateWork(); }

        this.totalCurrency = null;
        this.rowCountChanged = true;
    };

    public updateRowsCellsValues (forceUpdate?): void {
        const updatedRowsToRegroupDataArray = [];

        // if (this.columnsIndexWithColoringByPrevValue.length)
        //    this.ResetColoring(ro);

        const len = this.rowsArray.length;
        for (let i = 0; i < len; i++) {
            const row = this.rowsArray[i];
            // TODO
            if (isNullOrUndefined(row?.item)) { continue; }

            // if (this.columnsIndexWithColoringByPrevValue.length)
            //  this.ResetColoring(row);
            // TODO. refactor. Optimize.
            const oldGroupName = this.GetGroupKey(row);

            row.FillRowByItem(row.item, forceUpdate);
            (row as QuickTableGroupRow<ItemType>).isDirty = true;

            const newGroupName = this.GetGroupKey(row);
            if (newGroupName !== oldGroupName) {
                updatedRowsToRegroupDataArray.push({
                    row,
                    oldGroupName
                });
            }
        }

        // this.ColorColumns();

        this.regroupUpdatedRows(updatedRowsToRegroupDataArray);
    }

    public ResetColoring (row: QuickTableRow<ItemType>): void {
        for (let j = 0; j < this.columnsIndexWithColoringByPrevValue.length; j++) {
            const colIndex = this.columnsIndexWithColoringByPrevValue[j];
            const col = this.columns[colIndex];

            if (!col.visible) {
                continue;
            }

            //
            const curCell = row.cells[colIndex];

            // Waiting
            if (curCell.ColoringOccured > 0) {
                curCell.ColoringOccured--;
            }

            // Reset color
            else if (curCell.ColoringOccured === 0) {
                curCell.BackColor = null;// curCell.BackColorRecover;
                curCell.ForeColor = null;// curCell.ForeColorRecover;
                curCell.LastColoring = 0;
                curCell.ColoringOccured = -1;
                curCell.isDirty = true;
            }
        }
    }

    public sortRowsArray (): void {
        if (this.groupByColumnIndex !== -1) {
            this.recreateRowsArrayFromGroupStorage();
        } else {
            this.rowsArray.sort(this.plainRowsArrayComparator.bind(this));
        }

        this.regroupSuperItems(); // (FIFO) #80010

        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public plainRowsArrayComparator (a: QuickTableRow<ItemType>, b: QuickTableRow<ItemType>): number {
        let cell1Value = a.id;
        let cell2Value = b.id;

        if (this.sortIndex !== -1) {
            cell1Value = a.cells[this.sortIndex].value;
            cell2Value = b.cells[this.sortIndex].value;
        }

        let res = 0;

        if (cell1Value < cell2Value) {
            res = -1;
        }

        if (cell1Value > cell2Value) {
            res = 1;
        }

        if (!this.asc) {
            res *= -1;
        }

        return res;
    }

    // TODO
    public InitializeDirect (item: ItemType): void {
        const newColumns = [];
        const len = item.ColumnCount();
        this.AssociatedItem = item;
        for (let i = 0; i < len; i++) {
            const newColumn = new QuickTableColumn();

            // Todo need share
            const par = item.GetColumnParams(i);

            newColumn.columnType = par.ColumnType;
            newColumn.beginGroup = par.BeginGroup;
            newColumn.MenuIndex = par.MenuIndex;
            newColumn.headerText = par.HeaderText();
            newColumn.headerKey = par.HeaderKey;
            newColumn.FormulaKey = par.FormulaKey;
            newColumn.width = par.Width;
            newColumn.visible = par.Visible;
            newColumn.visibleDefault = par.Visible;
            newColumn.hidden = par.Hidden || Resources.isHidden(par.HeaderKey);
            newColumn.ToolTipHeaderText = par.ToolTip;
            newColumn.ShowTotal = par.ShowTotal;
            newColumn.EmptyValue = par.EmptyValue;
            newColumn.maxFilterIndex = par.MaxFilterIndex;

            newColumn.displayedIndex = i;
            newColumn.allowGrouping = par.AllowGrouping;
            newColumn.AutoGenerated = par.AutoGenerated;

            newColumn.CanEdit = par.CanEdit;

            switch (par.ColumnType) {
            case QuickTableColumnType.COL_UPDOWN_NUMERIC:
            case QuickTableColumnType.COL_MORELESS_NUMERIC:
            case QuickTableColumnType.COL_SIMPLE_NUMERIC:
            case QuickTableColumnType.QUANTITY:
                newColumn.sortRule = QTSortRuleType.Numeric;
                break;
            case QuickTableColumnType.COL_DATE_SORT:
            case QuickTableColumnType.COL_TIME_SORT:
                newColumn.sortRule = QTSortRuleType.Data;
                break;
            default:
                newColumn.sortRule = QTSortRuleType.Text;
                break;
            }

            newColumns.push(newColumn);
        }

        this.columns = newColumns;

        this.populateHeaderContextMenu(newColumns);
        this.createGroupByContextMenuItems(newColumns);
    }

    public AddItem (item: ItemType): QuickTableRow<ItemType> | null {
        if (!isNullOrUndefined(this.rows[item.ItemId])) {
            this.rows[item.ItemId].FillRowByItem(item);
            (this.rows[item.ItemId] as QuickTableGroupRow<ItemType>).isDirty = true;
            return null;
        }

        const row = this.AddRow(new QuickTableRow(item, this));
        if (this.sortIndex !== -1) {
            this.sortRowsArray();
        }
        this.OnAddItem.Raise(row);
        return row;
    }

    public getItemById (key): QuickTableRow<ItemType> | null {
        const res = this.rows[key];
        return res || null;
    }

    public getTableItemsIds (): string[] {
        return Object.keys(this.rows);
    }

    public getSelectedTableItems (): ItemType[] {
        const selectedItems: ItemType[] = [];
        for (let i = 0; i < this.selectedRowIds.length; i++) {
            const row = this.getItemById(this.selectedRowIds[i]);
            if (!isNullOrUndefined(row?.item)) {
                selectedItems.push(row.item);
            }
        }
        return selectedItems;
    }

    public getSelectedRowsIndexes (): number[] {
        const selectedRowIndexes: number[] = [];
        for (let i = 0; i < this.selectedRowIds.length; i++) {
            const row = this.getItemById(this.selectedRowIds[i]);
            selectedRowIndexes.push(this.rowsArray.indexOf(row));
        }

        return selectedRowIndexes;
    }

    public setSelectedRowsByIndexes (indexes: number[]): void {
        const selectedRowIds = [];
        for (let i = 0; i < indexes.length; i++) {
            const index = indexes[i];
            selectedRowIds.push(this.rowsArray[index].id);
        }

        this.selectedRowIds = selectedRowIds;
        this.redraw();
    }

    public RemoveItem (id: string | number): void {
        this.RemoveRow(id);
    }

    // TODO.
    public RemoveSelectedItems (): void {
        const selectedRowIds = this.selectedRowIds;
        while (selectedRowIds.length > 0) {
            this.RemoveRow(selectedRowIds[0]);
        }
    }

    //
    public onMouseDown (event): void {
        if (this.movingType === QuickTableMovingType.ColumnMoving &&
        event.button == MouseButtons.Middle) {
            return;
        }

        this.columnReordering = false;

        // Row selection
        this.lastMouseDown = new Point(event.offsetX, event.offsetY);

        const hitTestInfo = this.HitTest(event.offsetX, event.offsetY);

        this.movingType = QuickTableMovingType.None;
        this.activeColumnIndex = hitTestInfo.columnIndex;

        if (event.button === 2) {
            if (hitTestInfo.zone === QuickTableZone.Header && this.canShowHeaderMenu) {
                contextMenuHandler.Show(this.headerContextMenuItems, event.clientX, event.clientY, { isMultiClick: true });
            } else if (hitTestInfo.zone === QuickTableZone.Table) {
                if (!isNullOrUndefined(this.beforeTableContextMenuShowing)) {
                    this.beforeTableContextMenuShowing(this);
                }

                contextMenuHandler.Show(this.createTableContextMenuItems(),
                    event.clientX,
                    event.clientY);
            }
            return;
        }

        // Click by scroll
        if (hitTestInfo.zone === QuickTableZone.VerticalScroll) {
            this.movingType = QuickTableMovingType.VerticalScroll;
            this.scroll.mouseDown(event);
        }
        // Click by header
        else if (hitTestInfo.zone === QuickTableZone.Header) {

        }
        // Click on header grid - start resize columns
        else if (
            hitTestInfo.zone === QuickTableZone.HeaderGrid ||
        hitTestInfo.zone === QuickTableZone.VerticalResizeSeparator) {
            if (hitTestInfo.columnIndex !== -1 && !hitTestInfo.lastVisibleSortedColumn && this.columnResizeAllowed && !this.isActiveEditControl) {
                this.movingType = QuickTableMovingType.ColumnResize;
                this.activeColumnIndex = hitTestInfo.columnIndex;
            }
        }
        // Click by row
        else {
            const ctrl = event.ctrlKey;
            // todo support SHIFT
            if (!ctrl) this.selectedRowIds = [];

            if (hitTestInfo.rowIndex !== -1) {
                const row = this.rowsArray[hitTestInfo.rowIndex];

                if (row.isGroupRow) {
                    this.processGroupRowMouseDown(row);
                    return;
                }

                const clickedRowIdx = this.selectedRowIds.indexOf(row.id);
                if (clickedRowIdx === -1) {
                    this.selectedRowIds.push(row.id);
                } else if (ctrl) {
                    this.selectedRowIds.splice(clickedRowIdx, 1);
                }
            }

            if (hitTestInfo.columnIndex !== -1) {
                const cellIndex = this.sortedColumns[hitTestInfo.columnIndex].PRIVATE.index;
                const cell = this.rowsArray[hitTestInfo.rowIndex].cells[cellIndex];
                if (!isNullOrUndefined(this.ClickableCellsIndexesAndEvents[cellIndex])) {
                    this.ClickableCellsIndexesAndEvents[cellIndex](this, cell, hitTestInfo.rowIndex);
                }
            }

            // Cell editing.
            if (hitTestInfo.rowIndex !== -1 && hitTestInfo.columnIndex !== -1) {
                const cellIndex = this.sortedColumns[hitTestInfo.columnIndex].PRIVATE.index;
                const cell = this.rowsArray[hitTestInfo.rowIndex].cells[cellIndex];
                if (!isNullOrUndefined(cell) && !cell.ReadOnly && !isNullOrUndefined(cell.QuickTableEditingInfo)) {
                    this.BeginEdit(
                        cell.QuickTableEditingInfo,
                        cell,
                        this.rowsArray[hitTestInfo.rowIndex],
                        hitTestInfo.columnIndex,
                        hitTestInfo.realColumnIndex,
                        event);
                }
            }

            this.needRedrawBackground = true;
            this.OnSelectionChanged.Raise(/* fromMouseClick */true, this);
        }

        // Refresh
        this.needRedraw = true;
        this.mouseDownWasOnMe = true;

        this.onTableMouseDown.Raise(hitTestInfo);
    }

    public onMouseMove (event): void {
        const hitTestInfo = this.HitTest(event.offsetX, event.offsetY);
        this.scroll.onMouseMove(event);
        // Column reordering
        // выполняется только если смещение мышки было больше значения mouseOffset
        if (event.button == MouseButtons.Left /* && AllowColumnsReordering */ &&
        !this.columnReordering &&
        (Math.abs(this.lastMouseDown.X - event.offsetX) > QuickTable.MouseOffset || Math.abs(this.lastMouseDown.Y - event.offsetY) > QuickTable.MouseOffset) /* && !ReadOnly */ &&
        hitTestInfo.zone === QuickTableZone.Header &&
        this.movingType === QuickTableMovingType.None &&
        event.buttons !== 0 &&
        this.mouseDownWasOnMe &&
        this.isMoveColumnAllowed) {
            this.columnReordering = true;
            this.movingType = QuickTableMovingType.ColumnMoving;
            this.activeColumnIndex = hitTestInfo.columnIndex;
            if (this.activeColumnIndex !== -1) {
                this.verticalScrollClickDelta = this.sortedColumns[this.activeColumnIndex].PRIVATE.drawingX - this.lastMouseDown.X;
            }
        }

        // Cursor.
        if (this.movingType !== QuickTableMovingType.ColumnResize) {
            this.currentCursor =
            this.columnResizeAllowed &&
                (hitTestInfo.zone === QuickTableZone.HeaderGrid ||
                    hitTestInfo.zone === QuickTableZone.VerticalResizeSeparator) &&
                hitTestInfo.columnIndex !== -1 &&
                !hitTestInfo.lastVisibleSortedColumn
                ? 'col-resize'
                : 'default';
        }

        // Click by scroll
        if (this.movingType === QuickTableMovingType.VerticalScroll) {
            this.scroll.mouseMove(event);
        }

        // Resizing columns
        else if (this.movingType === QuickTableMovingType.ColumnResize) {
            const activeColumnIndex = this.activeColumnIndex;
            const lastMouseMoveHitTestInfo = this.lastMouseMoveHitTestInfo;

            if (activeColumnIndex !== -1 && lastMouseMoveHitTestInfo != null) {
                const delta = Math.round(hitTestInfo.x - lastMouseMoveHitTestInfo.x);

                const change = delta / this.lastWKoef;

                const columns = this.sortedColumns;
                const activeCol = columns[activeColumnIndex];

                let newW = change + activeCol.width;
                if (newW < 7) {
                    newW = 7;
                }

                if (activeCol.width !== newW) {
                    activeCol.width = newW;
                    this.CorrectColumnsWidth(change);
                    this.needRedrawBackground = true;
                    this.needRedraw = true;
                    this.OnColumnResize.Raise();
                }
            }
        } else if (this.movingType === QuickTableMovingType.ColumnMoving && this.activeColumnIndex !== -1) {
            this.needRedrawBackground = true;
        } else if (this.enableRowHover) {
            this.ChangeHoverRow(hitTestInfo);
        }

        if (this.editableRowID != null) {
            this.editableRowID = null;
            this.editableColID = null;
            this.needRedraw = true;
            this.needRedrawBackground = true;
        }

        if (hitTestInfo.columnIndex >= 0 && hitTestInfo.rowIndex >= 0 && this.sortedColumns[hitTestInfo.columnIndex].CanEdit) {
            const item = this.rowsArray[hitTestInfo.rowIndex].item;
            const columnID = this.sortedColumns[hitTestInfo.columnIndex].PRIVATE.index;
            if (item?.isModifyAllowed(columnID)) {
                this.editableColID = hitTestInfo.columnIndex;
                this.editableRowID = hitTestInfo.rowIndex;
                this.needRedraw = true;
                this.needRedrawBackground = true;
            }
        }

        // TODO. Wrong concept, wrong place.
        // Showing tooltip.
        if (hitTestInfo.zone === QuickTableZone.Header &&
        this.movingType === QuickTableMovingType.None) {
            const column = this.columns[hitTestInfo.columnIndex];
            const ttText = column ? column.tooltipKey : null;
            this.OnExternalShowTooltipRequest.Raise(ttText, hitTestInfo.columnIndex);
        } else if (hitTestInfo.zone === QuickTableZone.Table &&
            hitTestInfo.rowIndex !== -1 && hitTestInfo.realColumnIndex !== -1) {
            const cell = this.rowsArray[hitTestInfo.rowIndex].cells[hitTestInfo.realColumnIndex];
            if (cell.CanShowTooltip()) {
                this.OnExternalShowTooltipRequest.Raise(cell.GetTooltipKey(), hitTestInfo.rowIndex, hitTestInfo.realColumnIndex);
            } else {
                // Hiding tooltip.
                this.OnExternalShowTooltipRequest.Raise(null);
            }
        } else {
        // Hiding tooltip.
            this.OnExternalShowTooltipRequest.Raise(null);
        }

        this.processGroupHitTestInfo(hitTestInfo, this.lastMouseMoveHitTestInfo);
        this.processCellControlHitTestInfo(hitTestInfo, this.lastMouseMoveHitTestInfo);

        this.lastMouseMove = new Point(event.offsetX, event.offsetY);
        this.lastMouseMoveHitTestInfo = hitTestInfo;

        this.onTableMouseMove.Raise(hitTestInfo);
    }

    public onMouseLeave (event): void {
    // TODO. Wrong concept, wrong place.
    // Hiding tooltip.
        this.OnExternalShowTooltipRequest.Raise(null);

        const hitTestInfo = this.HitTest(event.offsetX, event.offsetY);

        this.processGroupHitTestInfo(hitTestInfo, this.lastMouseMoveHitTestInfo);
        this.processCellControlHitTestInfo(hitTestInfo, this.lastMouseMoveHitTestInfo);

        this.lastMouseMove = new Point(event.offsetX, event.offsetY);
        this.lastMouseMoveHitTestInfo = hitTestInfo;
        this.hoveredRow = null;
        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public ChangeHoverRow (hitTestInfo?: HitTestInfo): void {
        if (isNullOrUndefined(hitTestInfo)) {
            return;
        }

        const row = this.rowsArray[hitTestInfo.rowIndex];
        if (row?.id !== this.hoveredRow?.id) {
            this.hoveredRow = row;
            this.needRedraw = true;
            this.needRedrawBackground = true;
        }
    }

    // #region Mostly Grouping stuff

    // Collapses/expands a group of rows.
    public processGroupRowMouseDown (groupRow: QuickTableRow<ItemType> | QuickTableGroupRow<ItemType>): void {
        if (!this.canUserCollapseGroups || !groupRow?.isGroupRow) {
            return;
        }

        this.updateGroupCollapsedState((groupRow as QuickTableGroupRow<ItemType>).GroupValue, !(groupRow as QuickTableGroupRow<ItemType>).collapsed);
    }

    public setGroupCollapsedState (groupRow: QuickTableGroupRow<ItemType>, collapsed: boolean): void {
        if (!groupRow?.isGroupRow) {
            return;
        }

        this.updateGroupCollapsedState(groupRow.GroupValue, collapsed);
    }

    public updateGroupCollapsedState (group, collapsed: boolean): void {
        const groupStorageItem = this.groupStorageDict[group];
        if (isNullOrUndefined(groupStorageItem)) {
            return;
        }

        groupStorageItem.groupHeaderRow.collapsed = collapsed;

        let needUpdate = false;
        const visible = !collapsed;
        const groupRowsDict = groupStorageItem.groupRowsDict;
        for (const groupRowKey in groupRowsDict) {
            const row = groupRowsDict[groupRowKey];
            needUpdate = row.visible !== visible || needUpdate;
            row.visible = visible;
            if (visible) {
                this.correctGroupRowVisibility(row);
            } // account linking and fifo trades visibility correction
        }

        if (!needUpdate) {
            return;
        }

        this.OnGroupsVisibilityChanged.Raise();

        this.updateScrollElementsCount();

        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public setGroupByColumnIdx (idx: number): void {
        if (this.groupByColumnIndex === idx) {
            return;
        }

        this.groupByColumnIndex = idx;

        if (this.groupByColumnIndex === -1) {
            this.clearAllGroupData();
            this.sortRowsArray();
        } else {
            this.regroupAll();
        }

        this.regroupSuperItems(); // (FIFO) #80010

        this.updateGroupRowVisibility();

        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    /**
     * Regroups all items while preserving the collapse state of each group.
     * Same as regroupAll() but with the restoration of previous collapsed states.
     */
    public regroupAllWithSameCollapseState (): void {
        const backUpCollapsedStates: Record<string, boolean> = {};

        for (const group in this.groupStorageDict) {
            const { groupHeaderRow } = this.groupStorageDict[group];
            backUpCollapsedStates[group] = groupHeaderRow.collapsed;
        }

        this.regroupAll();

        for (const group in this.groupStorageDict) {
            const { groupHeaderRow } = this.groupStorageDict[group];
            this.setGroupCollapsedState(groupHeaderRow, !!backUpCollapsedStates[group]);
        }
    }

    public regroupAll (): void {
        this.clearAllGroupData();

        const rowsArray = this.rowsArray;
        const len = rowsArray.length;
        for (let i = 0; i < len; i++) {
            this.addRowToGroupStorage(rowsArray[i]);
        }

        this.recreateRowsArrayFromGroupStorage();
    }

    public recreateRowsArrayFromGroupStorage (
        skipSortingOfRowsInGroups = false,
        sortByIndex = false): void {
        const groupStorageDict = this.groupStorageDict;
        if (isNullOrUndefined(groupStorageDict)) return;

        const groupOrderArray = Object.keys(groupStorageDict);
        let sortMethod = this.groupNameComparator.bind(this);
        if (sortByIndex) {
            sortMethod = this.groupHeaderRowSortIndexComparator.bind(this);
        }

        if (this.GroupIndexSorting) {
            sortMethod = this.groupRowSortGroupIndexComparator.bind(this);
        }
        groupOrderArray.sort(sortMethod);

        const plainRowsArrayComparator =
        sortByIndex
            ? QuickTable.groupRowsSortIndexComparator
            : this.plainRowsArrayComparator.bind(this);

        let newRowsArray = [];
        const len = groupOrderArray.length;
        for (let i = 0; i < len; i++) {
            const groupStorageItem = groupStorageDict[groupOrderArray[i]];

            newRowsArray.push(groupStorageItem.groupHeaderRow);

            const groupRowsArray = groupStorageItem.groupRowsArray;
            if (!skipSortingOfRowsInGroups) {
                groupRowsArray.sort(plainRowsArrayComparator);
            }

            newRowsArray = newRowsArray.concat(groupRowsArray);
        }

        this.rowsArray = newRowsArray;

        this.regroupSuperItems();

        this.updateScrollElementsCount();
    }

    public clearAllGroupData (): void {
        const groupStorageDict = this.groupStorageDict;
        this.groupStorageDict = {};

        const rowsArray = this.rowsArray;

        let needUpdateScrollElementsCount = false;

        for (const groupName in groupStorageDict) {
            const groupStorageItem = groupStorageDict[groupName];

            const groupRowsDict = groupStorageItem ? groupStorageItem.groupRowsDict : null;
            if (!groupRowsDict) continue;

            // Restoring rows visibility.                       // комментарии ниже могут пригодится если прилетит тикет по поводу того, что FIFO позиция не запоминает своего collapse состояния, когда убирается Group By
            // let collapseState = {}, laterStorage = []
            for (const groupRowKey in groupRowsDict) {
                const row = groupRowsDict[groupRowKey];
                row.visible = true;
                needUpdateScrollElementsCount = true;
                this.correctGroupRowVisibility(row); // account linking and fifo trades visibility correction
            /* if (row.item && row.item.superItemId)         // All this commented rows are for restore collapseFIFO state
            {
                if (!this.additionalFilter && row.item.isSubItem)
                    row.visible = false

                if (this.additionalFilter)
                {
                    if (row.item.isSubItem)    // change state of sub items now or later
                    {
                        if (collapseState.hasOwnProperty(row.item.superItemId))
                            row.visible = collapseState[row.item.superItemId]
                        else
                            laterStorage.push(row)
                    }
                    else
                        collapseState[row.item.ItemId] = row.collapsedFIFO  // remember collapse state of super item
                }
                else
                    if (row.item.isSubItem)
                        row.visible = false         // hide sub items if display trades off
            } */
            }
            // for (let i = 0; i < laterStorage.length; i++)       // for sub items that was earlier than super
            //    laterStorage[i].visible = collapseState[laterStorage[i].item.superItemId]

            // Removing group header rows.
            const idx = rowsArray.indexOf(groupStorageItem.groupHeaderRow);
            if (idx !== -1) {
                rowsArray.splice(idx, 1);
                needUpdateScrollElementsCount = true;
            }
        }

        // if (LinkedSystem.accLinkingActive)      // restore account filtration
        //     this.filterByAccountNumber(LinkedSystem.accountStorage[LinkedSystemAccLinkingValue])

        if (needUpdateScrollElementsCount) {
            this.updateScrollElementsCount();
        }
    }

    public addRowToGroupStorage (row: QuickTableRow<ItemType>, forceAddToGroupKey?, sortIndexForGroup?): void {
        if (isNullOrUndefined(row)) return;

        const groupStorageDict = this.groupStorageDict;
        if (isNullOrUndefined(groupStorageDict)) return;

        const groupName =
        isNullOrUndefined(forceAddToGroupKey)
            ? this.GetGroupKey(row)
            : forceAddToGroupKey;

        let groupStorageItem: QTGroupStorageItem = null;
        if (groupStorageDict.hasOwnProperty(groupName)) {
            groupStorageItem = groupStorageDict[groupName];
        } else {
            groupStorageItem = new QTGroupStorageItem();

            const groupHeaderRow = new QuickTableGroupRow(this);
            groupHeaderRow.GroupValue = groupName;
            groupHeaderRow.sortIndex = sortIndexForGroup;

            groupStorageItem.groupHeaderRow = groupHeaderRow;
            if (!isNullOrUndefined((row.item as any)?.GroupIndex)) {
                groupStorageItem.GroupIndex = (row.item as any).GroupIndex;
            }

            groupStorageDict[groupName] = groupStorageItem;
        }

        const groupRowsDict = groupStorageItem.groupRowsDict;
        if (groupRowsDict[row.id]) return;

        groupRowsDict[row.id] = row;

        const groupRowsArray = groupStorageItem.groupRowsArray;
        if (this.AddToEnd) {
            groupRowsArray.push(row);
        } else {
            groupRowsArray.splice(0, 0, row);
        }

        // Sync row visibility.
        row.visible = !groupStorageItem.groupHeaderRow.collapsed;
        if (row.visible) {
            this.correctGroupRowVisibility(row);
        } // account linking and fifo trades visibility correction
    }

    public removeRowFromGroupStorage (row: QuickTableRow<ItemType>, forceRemoveFromGroupKey?): void {
        if (isNullOrUndefined(row)) return;

        const groupStorageDict = this.groupStorageDict;
        if (isNullOrUndefined(groupStorageDict)) return;

        const groupName =
        isNullOrUndefined(forceRemoveFromGroupKey)
            ? this.GetGroupKey(row)
            : forceRemoveFromGroupKey;

        const groupStorageItem = groupStorageDict[groupName];
        if (isNullOrUndefined(groupStorageItem)) return;

        const groupRowsDict = groupStorageItem.groupRowsDict;
        if (isNullOrUndefined(groupRowsDict[row.id])) return;

        delete groupRowsDict[row.id];

        const groupRowsArray = groupStorageItem.groupRowsArray;
        const idx = groupRowsArray.indexOf(row);
        if (idx === -1) return;

        groupRowsArray.splice(idx, 1);

        if (!groupRowsArray.length) {
            delete groupStorageDict[groupName];
        }

        // Restoring row visibility.
        row.visible = true;
        this.correctGroupRowVisibility(row); // account linking and fifo trades visibility correction
    }

    public regroupUpdatedRows (updatedRowsToRegroupDataArray: any[]): void {
        if (isNullOrUndefined(updatedRowsToRegroupDataArray)) {
            return;
        }

        const len = updatedRowsToRegroupDataArray.length;
        for (let i = 0; i < len; i++) {
            const item = updatedRowsToRegroupDataArray[i];
            const oldGroupName = item.oldGroupName;
            const row = item.row;

            this.removeRowFromGroupStorage(row, oldGroupName);
            this.addRowToGroupStorage(row);
        }

        if (len) {
            this.recreateRowsArrayFromGroupStorage(/* skipSortingOfRowsInGroups */true);
        }
    }

    public correctGroupRowVisibility (row: QuickTableRow<ItemType>): void // fix visibility when Group By + (AccountLinking or FIFO)
    {
        if (isNullOrUndefined(row.item) || !row.visible) {
            return;
        }

        const ls = LinkedSystem; const acc = row.item.GetCurrentAccount();

        if (row.item.superItemId && !row.collapsedFIFO && !row.item.isSubItem) // super item with uncollapsed row
        {
            row.collapsedFIFO = true;
        } // collapse state

        if ((!this.skipAccountFiltration && ls.accLinkingActive && acc &&
        acc.BstrAccount != ls.accountStorage[LinkedSystemAccLinkingValue]) || // Account Linking On + Group Open Bug Fix
        !this.additionalFilter && row.item.isSubItem) // Not display trades + Group Open Bug Fix
        {
            row.visible = false;
        }
    }

    public regroupSuperItems (): void // first show super item -> then sub items (restore this order here after populating or sorting)
    {
        const rows = this.rowsArray;
        const regroupedRows = [];
        const subRowsStorage = {};

        for (let i = 0; i < rows.length; i++) {
            const item = rows[i].item;
            if (item?.isSubItem) // sub item
            {
                if (!isNullOrUndefined(subRowsStorage[item.superItemId])) {
                    subRowsStorage[item.superItemId].push(rows[i]);
                } else {
                    subRowsStorage[item.superItemId] = [rows[i]];
                }
            } else // first add only super or other items
            {
                regroupedRows.push(rows[i]);
            }
        }

        const superKeys = Object.keys(subRowsStorage); // sub items left checking
        if (superKeys.length === 0) // no sub items
        {
            return;
        }

        let newRows = [];
        for (let i = 0; i < regroupedRows.length; i++) {
            const row = regroupedRows[i];
            newRows.push(row);
            if (row.item?.superItemId) // super item
            {
                const subRow = subRowsStorage[row.item.ItemId];
                if (!isNullOrUndefined(subRow)) {
                    newRows.splice.apply(newRows, [newRows.length, 0].concat(subRow)); // insert array from subRowsStorage (with sub items of current super item) inside newRows array
                    subRowsStorage[row.item.ItemId] = []; // clear storage for sub items left checking
                }
            }
        }

        for (let i = 0; i < superKeys.length; i++) {
            if (subRowsStorage[superKeys[i]].length > 0) // if subs left => there is no super for exist sub yet
            {
                newRows = newRows.concat(subRowsStorage[superKeys[i]]);
            }
        } // just add to the end

        this.rowsArray = newRows;
    }

    public groupHeaderRowSortIndexComparator (groupKey1, groupKey2): number {
        const groupStorageDict = this.groupStorageDict;
        const groupHeaderRow1 = groupStorageDict[groupKey1].groupHeaderRow;
        const groupHeaderRow2 = groupStorageDict[groupKey2].groupHeaderRow;

        return groupHeaderRow1.sortIndex - groupHeaderRow2.sortIndex;
    }

    public groupRowSortGroupIndexComparator (groupKey1, groupKey2): number {
        const groupStorageDict = this.groupStorageDict;
        const groupRow1 = groupStorageDict[groupKey1];
        const groupRow2 = groupStorageDict[groupKey2];

        if (!groupRow1.GroupIndex || !groupRow2.GroupIndex) {
            return this.groupNameComparator(groupKey1, groupKey2);
        }

        return groupRow1.GroupIndex - groupRow2.GroupIndex;
    }

    public static groupRowsSortIndexComparator (row1, row2): number {
        return row1.sortIndex - row2.sortIndex;
    }

    public groupNameComparator (groupKey1, groupKey2): number {
        if (this.sortIndex === -1 ||
        this.groupByColumnIndex !== this.sortIndex ||
        this.asc) {
            return groupKey1.localeCompare(groupKey2);
        }

        return groupKey2.localeCompare(groupKey1);
    }

    public getSubItemsRowsCount (countOnlyVisible: boolean): number // (FIFO) #80010 count sub items number for panel header (countOnlyVisible == false -> also count invisible items)
    {
        let count = 0;
        for (let i = 0; i < this.rowsArray.length; i++) {
            const row = this.rowsArray[i];
            if (row && (row.visible || !countOnlyVisible) && row.item?.isSubItem) {
                count++;
            }
        }
        return count;
    }

    // Group header rows are excluded.
    public getDataRowsCount (): number {
        if (this.groupByColumnIndex === -1) {
            return this.rowsArray.length;
        }

        let count = 0;

        const groupStorageDict = this.groupStorageDict;
        for (const groupKey in groupStorageDict) {
            count += groupStorageDict[groupKey].groupRowsArray.length;
        }

        return count;
    }

    // #endregion

    // Header + borders + VISIBLE rows within/beyond scrollArea.
    public getVisibleDataHeight (): number {
        const rowH = this.rowHeight;
        const borderH = 1;

        let h = this.showHeader ? rowH : 0;

        const rows = this.rowsArray;
        if (!rows) return h;

        const len = rows.length;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) continue;

            h += rowH + borderH;
        }

        return h;
    }

    public processGroupHitTestInfo (newHitTestInfo: HitTestInfo, oldHitTestInfo: HitTestInfo): void {
        const rowsArray = this.rowsArray;
        let isDirty = false;

        const newRowIdx = !isNullOrUndefined(newHitTestInfo) ? newHitTestInfo.rowIndex : -1;
        const oldRowIdx = !isNullOrUndefined(oldHitTestInfo) ? oldHitTestInfo.rowIndex : -1;
        const newRow = rowsArray[newRowIdx];

        if (newRowIdx === oldRowIdx && !isValidString((newRow as QuickTableGroupRow<ItemType>)?.infoIconTooltip)) {
            return;
        }

        if (newRow?.isGroupRow) {
            (newRow as QuickTableGroupRow<ItemType>).isDirty = true;
            isDirty = true;
        }

        const oldRow = rowsArray[oldRowIdx];
        if (oldRow?.isGroupRow) {
            (oldRow as QuickTableGroupRow<ItemType>).isDirty = true;
            isDirty = true;
        }

        if (isDirty) {
            this.needRedraw = true;
        }
    }

    public processCellControlHitTestInfo (newHitTestInfo: HitTestInfo, oldHitTestInfo: HitTestInfo): void {
        let isDirty = false;

        const newCell = this.getCellFromHitTestInfo(newHitTestInfo);
        const oldCell = this.getCellFromHitTestInfo(oldHitTestInfo);

        if (newCell === oldCell && oldCell !== null) {
            if (oldCell !== null) {
                if (oldCell.QuickTableEditingInfo === null) {
                    return;
                }
            } else {
                return;
            }
        }

        // Simulates cell mouseover.
        if (!isNullOrUndefined(newCell)) {
            const newQuickTableEditingInfo = newCell.QuickTableEditingInfo;
            if (!newCell.ReadOnly && newQuickTableEditingInfo) {
                this.needRedrawBackground = true;
                newCell.isDirty = true;
                isDirty = true;
            }
        }

        // Simulates cell mouseleave.
        if (!isNullOrUndefined(oldCell)) {
            const oldQuickTableEditingInfo = oldCell.QuickTableEditingInfo;
            if (!oldCell.ReadOnly && oldQuickTableEditingInfo) {
                this.needRedrawBackground = true;
                oldCell.isDirty = true;
                isDirty = true;
            }
        }

        if (isDirty) {
            this.needRedraw = true;
        }
    }

    public getCellFromHitTestInfo (hitTestInfo: HitTestInfo): QuickTableCell | null {
        if (isNullOrUndefined(hitTestInfo)) return null;

        const rowIdx = hitTestInfo.rowIndex;
        if (rowIdx === -1) return null;

        const colIdx = hitTestInfo.columnIndex;
        if (colIdx === -1) return null;

        const row = this.rowsArray[rowIdx];
        if (!row) return null;

        return row.cells[this.sortedColumns[colIdx].PRIVATE.index];
    }

    public BeginEdit (info: QuickTableEditingInfo, cell, row, columnIndex, realColumnIndex, event): void {
        if (isNullOrUndefined(info) || !info.IsEditable) {
            return;
        }

        const data = {
            table: this,
            row,
            cell,
            columnIndex,
            realColumnIndex,
            controlType: info.ControlType,
            subItemIndex: null
        };

        switch (info.ControlType) {
        case DynProperty.ACCOUNT:
        case DynProperty.INSTRUMENT:
        case DynProperty.COMBOBOX_COMBOITEM_TIF:
        case DynProperty.COMBOBOX:
        case DynProperty.COLOR:
            this.OnExternalCellEditRequest.Raise(data);
            break;
        case DynProperty.DOUBLE:
            this.OnExternalCellEditRequest.Raise(data);
            event.preventDefault();
            break;
        case DynProperty.LINK:
        case DynProperty.LINK_WITH_TEXT:
        case DynProperty.BOOLEAN:
            this.AfterEditItem.Raise(data);
            break;
        case DynProperty.CELLBUTTON:
            this.AfterButtonItem.Raise(data);
            break;
        case DynProperty.EXPAND_SUPER_POSITION_BUTTON_AND_TEXT:{
            const priv = this.sortedColumns[columnIndex].PRIVATE;
            const offsetX = 7; const offsetY = 6; const imgSize = 11;
            const xI = Math.round(priv.drawingX + offsetX) - 0.5;
            const yI = Math.round(row.displayRectangle.Y + row.displayRectangle.Height / 2 - offsetY) - 0.5;

            const hover = QuickTable.isCellControlHovered(xI, yI, imgSize, imgSize, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (hover && this.additionalFilter) {
                this.AfterExpandItem.Raise(data, event);
            }
            break;
        }

        case DynProperty.INFO_PICTURE:
        case DynProperty.INFO_PICTURE_RIGHT_AND_TEXT:
        case DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT:
        case DynProperty.ORDER_BUTTON:
        case DynProperty.TRADE_BUTTON:
        case DynProperty.CLOSE_BUTTON:
        case DynProperty.ITCHART_ADVANCED:
        case DynProperty.SELL_ASSET_BUTTON:
        case DynProperty.PLACE_BUTTON:{
            const pr = this.sortedColumns[columnIndex].PRIVATE;
            const cellW = pr.drawingWidth;
            const cellX = pr.drawingX;
            const cellH = row.displayRectangle.Height;
            const cellY = row.displayRectangle.Y;

            let x = Math.round(cellX + cellW / 2 - 7) - 0.5;

            const cType = info.ControlType;
            if (cType === DynProperty.INFO_PICTURE_RIGHT_AND_TEXT || cType === DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT) { x = Math.round(cellX + cellW - 7 - 17) - 0.5; }

            const y = Math.round(cellY + cellH / 2 - 7.5) - 0.5;

            const hover = QuickTable.isCellControlHovered(x, y, 17, 17, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (hover) {
                this.OnPaintedPictureButtonClick.Raise(data, event);
            }
            break;
        }

        case DynProperty.ALERT_BUTTONS_GROUP:{
            const paramColumn = this.sortedColumns[columnIndex].PRIVATE;

            const x_column = paramColumn.drawingX;
            const y_column = row.displayRectangle.Y;
            const width = paramColumn.drawingWidth;
            const height = row.displayRectangle.Height;

            const x1_image = Math.round(x_column + width - 64) - 0.5;
            const x2_image = x1_image + 20;
            const x3_image = x2_image + 20;
            const y_image = Math.round(y_column + height / 2 - 7.5) - 0.5;

            const isHoverRectangle = QuickTable.isCellControlHovered(x1_image, y_image, width, height, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (!isHoverRectangle) { break; }

            let isHoveredImage = QuickTable.isCellControlHovered(x1_image, y_image, 17, 17, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (isHoveredImage) {
                data.subItemIndex = AlertSubImage.PLAY_STOP;
                this.OnPaintedPictureButtonClick.Raise(data, event);
                break;
            }

            isHoveredImage = QuickTable.isCellControlHovered(x2_image, y_image, 17, 17, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (isHoveredImage) {
                data.subItemIndex = AlertSubImage.UPDATE;
                this.OnPaintedPictureButtonClick.Raise(data, event);
                break;
            }

            isHoveredImage = QuickTable.isCellControlHovered(x3_image, y_image, 17, 17, this.lastMouseDown.X, this.lastMouseDown.Y);
            if (isHoveredImage) {
                data.subItemIndex = AlertSubImage.CLOSE;
                this.OnPaintedPictureButtonClick.Raise(data, event);
                break;
            }

            break;
        }
        }
    }

    public afterExternalCellEdit (value, tag): void {
        const controlType = tag.cell.QuickTableEditingInfo.ControlType;
        switch (controlType) {
        case DynProperty.DOUBLE:
            tag.newValue = Number(MathUtils.formatValueWithEps(value));
            break;
        case DynProperty.COMBOBOX:
        case DynProperty.COMBOBOX_COMBOITEM_TIF:
        case DynProperty.ACCOUNT:
        case DynProperty.INSTRUMENT:
            tag.newValue = value;
            break;
        case DynProperty.COLOR:
            tag.newValue = Color.rgbaToHex(value);
            break;
        }
        this.AfterEditItem.Raise(tag);
        this.needRedrawBackground = true;
        this.needRedraw = true;
    };

    //
    //
    //
    public CorrectColumnsWidth (change: number): void {
    // Определяем, видимые колонки стоящие за нашей
        let visColCount = 0;
        const startIndex = this.sortedColumns.indexOf(this.sortedColumns[this.activeColumnIndex]);
        for (let i = startIndex + 1; i < this.sortedColumns.length; i++) {
            if (this.sortedColumns[i].visible && !this.sortedColumns[i].hidden) {
                visColCount++;
            }
        }

        if (visColCount > 0) {
            const colChange = change / visColCount;
            for (let i = startIndex + 1; i < this.sortedColumns.length; i++) {
                const sortCol = this.sortedColumns[i];
                if (sortCol.visible && !sortCol.hidden) {
                    sortCol.resizer.increaseWidth(colChange, this.tableResizer.getMinWidth(sortCol.PRIVATE.index), this.lastWKoef);
                }
            }
        }
    }

    public onResizeCorrectColumnsWidth (): void {
        let visColCount = 0;
        let totalWidth = 0;
        let totalMinWidth = 0;
        this.tableResizer.ClearOnResizeData();
        for (let i = 0; i < this.sortedColumns.length; i++) {
            const col = this.sortedColumns[i];
            if (col.visible && !col.hidden) {
                visColCount++;
                totalWidth += col.width * this.lastWKoef;
                const minWidth = this.tableResizer.getMinWidth(col.PRIVATE.index);
                totalMinWidth += minWidth;
                const item = new QuickTableResizerItem();
                item.width = col.PRIVATE.drawingWidth;
                item.minWidth = minWidth;
                item.index = i;
                this.tableResizer.AddOnResizeData(item);
            }
        }
        if (totalMinWidth <= 0) { return; }
        if (visColCount > 0 && this.width !== 0 && this.prevWidth !== 0) {
            let coef = (this.width - this.prevWidth) / visColCount;
            if (totalWidth < totalMinWidth) { coef = (totalWidth - totalMinWidth) / visColCount; }
            const sizeItems: QuickTableResizerItem[] = this.tableResizer.GetOnResizeData();

            if (coef !== 0) {
                for (const item of sizeItems) {
                    const col = this.sortedColumns[item.index];
                    col.resizer.setWidth(item.width + coef, item.minWidth);
                }
            }
        }
    }

    public onMouseUp (event): void {
        const hitTestInfo = this.HitTest(event.offsetX, event.offsetY);

        // Click by scroll
        if (hitTestInfo.zone === QuickTableZone.VerticalScroll) {
            this.scroll.mouseUp(event);
        }

        if (event.button === MouseButtons.Left && !this.columnReordering /* && !ReadOnly */ && hitTestInfo.zone === QuickTableZone.Header && this.movingType === QuickTableMovingType.None && this.activeColumnIndex !== -1) {
            if (hitTestInfo.columnIndex !== -1 && !this.lockManualSorting) {
                const htColumnIndex = hitTestInfo.columnIndex;
                const htColumn = this.sortedColumns[htColumnIndex];
                if (!isNullOrUndefined(htColumn)) {
                    const realColumnIndex = htColumn.PRIVATE.index;

                    if (this.sortIndex == realColumnIndex) {
                        this.asc = !this.asc;
                    } else {
                        this.sortIndex = realColumnIndex;
                    }

                    this.sortRowsArray();
                }
            }
        } else if (this.movingType === QuickTableMovingType.ColumnMoving && this.activeColumnIndex !== -1 && this.lastMouseMoveHitTestInfo != null && this.lastMouseMoveHitTestInfo.columnIndex !== -1) {
            const lastInd = this.lastMouseMoveHitTestInfo.columnIndex;
            const activeColumnIndex = this.activeColumnIndex;
            if (lastInd !== this.activeColumnIndex) {
                let prev = false;
                if (this.sortedColumns[lastInd].displayedIndex - this.sortedColumns[activeColumnIndex].displayedIndex === 1) {

                } else if (this.sortedColumns[lastInd].displayedIndex - this.sortedColumns[activeColumnIndex].displayedIndex === -1) {
                    prev = true;
                } else if (event.offsetX - this.sortedColumns[lastInd].PRIVATE.drawingX < this.sortedColumns[lastInd].PRIVATE.drawingWidth / 2) {
                    prev = true;
                }
                this.SwitchDisplayIndex(this.sortedColumns[activeColumnIndex], this.sortedColumns[lastInd], prev);
            }
        }
        this.activeColumnIndex = -1;
        this.movingType = QuickTableMovingType.None;

        this.needRedrawBackground = true;
        this.needRedraw = true;

        // Document.releaseCapture() is deprecated. Use Element.releasePointerCapture() instead. More information https://developer.mozilla.org/docs/Web/API/Element/releasePointerCapture
        if ((document as any).releaseCapture) {
            (document as any).releaseCapture();
        }

        // Cursor.
        this.currentCursor =
        this.columnResizeAllowed &&
            (hitTestInfo.zone === QuickTableZone.HeaderGrid ||
                hitTestInfo.zone === QuickTableZone.VerticalResizeSeparator) &&
            hitTestInfo.columnIndex !== -1 &&
            !hitTestInfo.lastVisibleSortedColumn
            ? 'col-resize'
            : 'default';
        this.mouseDownWasOnMe = false;

        this.onTableMouseUP.Raise(hitTestInfo);
    }

    public onDoubleClick (event): boolean {
        const hittestInfo = this.HitTest(event.original.offsetX, event.original.offsetY);
        if (hittestInfo.zone === QuickTableZone.Header) {
            return false;
        }
        this.onTableMouseDoubleClick.Raise(this, hittestInfo);
        return true;
    }

    public onMouseWheel (event): void {
        if (!this.scroll.Visible) {
            return;
        }

        this.scroll.mouseWheel(ScrollUtils.IsScrollUp(event.deltaY));
    }

    public onKeyUp (): void { };

    public onKeyDown (): void {
        if (this.trySelectAll()) return;

        const keyProc = KeyEventProcessor;
        this.trySelectUpDown(
            keyProc.currentButton,
            keyProc.isShiftModOnly());
    }

    public trySelectAll (): boolean {
        const keyProc = KeyEventProcessor;
        if (!keyProc.isCtrlModOnly() || keyProc.currentButton !== KeyCode.A) {
            return;
        }

        const selRowIds = this.selectedRowIds;
        selRowIds.splice(0, selRowIds.length);

        const rowsArray = this.rowsArray;
        const len = rowsArray.length;

        for (let i = 0; i < len; i++) {
            const row = rowsArray[i];
            if (row.isGroupRow) continue;

            selRowIds.push(row.id);
        }

        this.afterSelectionChanged(true);
        return true;
    }

    public selectById (idArray: number[]): void {
        if ((idArray?.length) === 0) {
            return;
        }

        const newSelRowIds = [];

        const rowDict = this.rows;
        const len = idArray.length;
        for (let i = 0; i < len; i++) {
            const id = idArray[i];
            const row = rowDict[id];
            if (!row?.isGroupRow) {
                newSelRowIds.push(id);
            }
        }

        if (newSelRowIds.length === 0) {
            return;
        }

        this.selectedRowIds = newSelRowIds;
        this.afterSelectionChanged(true);
    }

    public trySelectUpDown (key: KeyCode, multiSelect: boolean): boolean {
        if (key !== KeyCode.UP &&
        key !== KeyCode.DOWN &&
        key !== KeyCode.HOME &&
        key !== KeyCode.END &&
        key !== KeyCode.PAGE_UP &&
        key !== KeyCode.PAGE_DOWN) { return; }

        const rowsArray = this.rowsArray;
        const rowsArrayLen = rowsArray.length;
        if (rowsArrayLen === 0) return;

        const rows = this.rows;
        let selRowIds = this.selectedRowIds;

        const up =
        key === KeyCode.UP ||
        key === KeyCode.HOME ||
        key === KeyCode.PAGE_UP;

        if (selRowIds.length === 0 && !up && !rowsArray[0].isGroupRow) {
            selRowIds.push(rowsArray[0].id);

            this.afterSelectionChanged();
            return true;
        } else if (selRowIds.length > 0) {
        // важно направление выделения
            const startIdx = rowsArray.indexOf(rows[selRowIds[0]]);
            let lastIdx = rowsArray.indexOf(rows[selRowIds[selRowIds.length - 1]]);
            // если область выделения уменьшается
            if (multiSelect && ((up && startIdx < lastIdx) || (!up && startIdx > lastIdx)) &&
            selRowIds.indexOf(rowsArray[lastIdx].id)) {
                const idx = selRowIds.indexOf(rowsArray[lastIdx].id);
                if (idx !== -1) {
                    selRowIds.splice(idx, 1);

                    this.afterSelectionChanged();
                    return true;
                }
            } else { // если область выделения увеличивается, иначе выбирается новая строка
                lastIdx += up ? -1 : 1;
                // ищем видимую строку, пока не достигаем края массива
                while ((up && lastIdx >= 0) || (!up && lastIdx < rowsArray.length)) {
                    const lastIdxRow = rowsArray[lastIdx];
                    if (lastIdxRow?.visible && !lastIdxRow.isGroupRow /* && lastIdxRow.selectable &&
                    lastIdxRow.visibleByGrouping && lastIdxRow.visibleBySearching */) {
                        if (!multiSelect) {
                            selRowIds = [];
                        } // выделяется только одна строка

                        selRowIds.push(lastIdxRow.id);
                        this.selectedRowIds = selRowIds;

                        this.afterSelectionChanged();
                        return true;
                    }
                    lastIdx += up ? -1 : 1;// определяется направление выделения, -1: вверх, 1: вниз
                }
            }
        }
    }

    public afterSelectionChanged (disallowScrollToLastSelected = false): void {
        const scroll = this.scroll;
        const selRowIds = this.selectedRowIds;
        const selRowIds_len = selRowIds.length;
        if (!disallowScrollToLastSelected && scroll.Visible && selRowIds_len) {
            const lastSelectedRow = this.rows[selRowIds[selRowIds_len - 1]];
            const idx = this.rowsArray.indexOf(lastSelectedRow);
            if (idx !== -1) {
                scroll.trailingScroll(idx);
            }
        }

        // Erasing hover as keyboard navigation is being used.
        this.hoveredRow = null;
        this.OnSelectionChanged.Raise(false, this);

        if (!this.isUpdatingNow) {
            this.needRedrawBackground = true;
            this.needRedraw = true;
        }
    }

    // TODO. Refactor.
    // Get info about clicked area
    public HitTest (x: number, y: number): HitTestInfo {
        const res = new HitTestInfo(x, y);

        const sortedColumns = this.sortedColumns;
        const len_sortedColumns = sortedColumns.length;
        const lastVisibleSortedColumnIdx = this.lastVisibleSortedColumnIdx;

        // Click on scroll
        if (this.scroll.Visible && this.scroll.containerRectangle.Contains(x, y)) {
            res.zone = QuickTableZone.VerticalScroll;
        }
        // Click on header
        else if (this.showHeader && y < this.rowHeight) {
            res.zone = QuickTableZone.Header;

            let curX = 0;
            for (let i = 0; i < len_sortedColumns; i++) {
                const sortedColumn = sortedColumns[i];

                // skip unvisible column
                if (!sortedColumn.visible || sortedColumn.hidden) {
                    continue;
                }

                if (lastVisibleSortedColumnIdx === i) {
                    res.lastVisibleSortedColumn = true;
                }

                const drawingWidth = sortedColumn.PRIVATE.drawingWidth;
                const rightBorder = curX + drawingWidth;

                // Click on header grid?
                if (x > rightBorder - QuickTable.ResizeColumnsAreaPixel && x < rightBorder + QuickTable.ResizeColumnsAreaPixel && !this.isActiveEditControl) {
                    res.zone = QuickTableZone.HeaderGrid;
                    res.columnIndex = i;
                    break;
                }

                if (x > curX && x < rightBorder) {
                    res.columnIndex = i;
                    res.realColumnIndex = this.columns.indexOf(sortedColumn);
                    break;
                }

                curX += drawingWidth;
            }
        }
        // Click on rows area
        else {
            const ClientH = this.height;
            const rowsArray = this.rowsArray;
            const len = !isNullOrUndefined(rowsArray) ? rowsArray.length : 0;
            for (let i = this.getFirstVisibleScrollIndex(); i < len; i++) {
                const row = rowsArray[i];
                if (!row.visible) continue;

                const rowRect = row.displayRectangle;
                if (rowRect.Contains(x, y)) {
                    res.rowIndex = i;
                    break;
                }

                // search only in displayed rows
                if (rowRect.Y > ClientH) {
                    break;
                }
            }

            if (res.rowIndex !== -1) {
                const useVerticalSeparatorForResizing = this.useVerticalSeparatorForResizing;
                const ResizeColumnsAreaPixel = QuickTable.ResizeColumnsAreaPixel;
                let curX = 0;
                for (let i = 0; i < len_sortedColumns; i++) {
                    const sortedColumn = sortedColumns[i];

                    // skip unvisible column
                    if (!sortedColumn.visible || sortedColumn.hidden) {
                        continue;
                    }

                    if (lastVisibleSortedColumnIdx === i) {
                        res.lastVisibleSortedColumn = true;
                    }

                    const drawingWidth = sortedColumn.PRIVATE.drawingWidth;
                    const rightBorder = curX + drawingWidth;

                    // Click on header grid?
                    if (useVerticalSeparatorForResizing &&
                    x > rightBorder - ResizeColumnsAreaPixel &&
                    x < rightBorder + ResizeColumnsAreaPixel) {
                        res.zone = QuickTableZone.VerticalResizeSeparator;
                        res.columnIndex = i;
                        res.realColumnIndex = this.columns.indexOf(sortedColumn);
                        break;
                    }

                    if (x > curX && x < rightBorder) {
                        res.columnIndex = i;
                        res.realColumnIndex = this.columns.indexOf(sortedColumn);
                        break;
                    }

                    curX += drawingWidth;
                }
            }
        }

        return res;
    }

    public setHiddenColumnsData (hiddenColumnsData: Record<number, boolean>): void {
        if (isNullOrUndefined(hiddenColumnsData) || ((this.columns?.length) === 0)) {
            return;
        }

        let columnsChanged = false;
        const columns = this.columns;

        for (const colIdx in hiddenColumnsData) {
            const hide = hiddenColumnsData[colIdx];

            const column = columns[colIdx];
            if (!isNullOrUndefined(column)) {
                column.hidden = hide;
                columnsChanged = true;
            }
        }

        if (columnsChanged) {
            this.UpdateSortedColumns();
            this.needRedrawBackground = true;
            this.needRedraw = true;
            this.populateHeaderContextMenu(columns);
            this.OnVisibleColumnChanged.Raise(this.getVisibleColumn(), this);
        }
    }

    public switchColumnVisibility (column: QuickTableColumn): void {
        column.visible = !column.visible;
        this.UpdateSortedColumns();
        this.needRedrawBackground = true;
        this.needRedraw = true;
        this.OnVisibleColumnChanged.Raise(this.getVisibleColumn(), this);
    }

    public getVisibleColumn (): number[] {
        const len = this.columns.length;
        const result: number[] = [];
        for (let i = 0; i < len; i++) {
            const col = this.columns[i];
            if (col.visible && !col.hidden) {
                result.push(i);
            }
        }

        return result;
    }

    public setVisibleColumn (visibleArr: number[]): void {
        let len = this.columns.length;
        for (let i = 0; i < len; i++) {
            this.columns[i].visible = false;
        }

        len = visibleArr.length;
        for (let i = 0; i < len; i++) {
            this.columns[visibleArr[i]].visible = true;
        }

        this.UpdateSortedColumns();
        this.needRedrawBackground = true;
        this.needRedraw = true;
        this.OnVisibleColumnChanged.Raise(visibleArr, this);
    }

    public createGroupByContextMenuItems (columns: QuickTableColumn[]): void {
        const callback = this.onGroupByMenuItemClicked.bind(this);

        const groupByColumnIndex = this.groupByColumnIndex;

        const len = columns.length;
        const items = [];
        for (let i = 0; i < len; i++) {
            const column = columns[i];
            if (column.hidden || !column.allowGrouping) {
                continue;
            }

            const newItem: any = {};
            newItem.text = column.headerText;
            newItem.checked = column.PRIVATE.index === groupByColumnIndex;
            newItem.canCheck = true;
            newItem.enabled = true;
            newItem.tag = column;
            newItem.event = callback;
            newItem.noCheckMark = true;

            items.push(newItem);
        }

        if (items.length === 0) {
            this.groupByContextMenuItems = null;
            return;
        }

        items.sort(QuickTable.QuickTableMenuComparer);

        const groupByParentItem: any = {};

        groupByParentItem.text = Resources.getResource('panel.menu.GroupBy');
        groupByParentItem.checked = false;
        groupByParentItem.canCheck = false;
        groupByParentItem.enabled = true;
        groupByParentItem.subitems = items;

        this.groupByContextMenuItems = groupByParentItem;
    }

    public onGroupByMenuItemClicked (menuItem): void {
        if (isNullOrUndefined(menuItem)) return;

        const column = menuItem.tag;
        if (isNullOrUndefined(column)) return;

        const groupByContextMenuItems = this.groupByContextMenuItems;
        if (isNullOrUndefined(groupByContextMenuItems)) return;

        const groupMenuItems = groupByContextMenuItems.subitems;

        const colIdx = menuItem.checked ? column.PRIVATE.index : -1;

        const len = groupMenuItems.length;
        for (let i = 0; i < len; i++) {
            const mi = groupMenuItems[i];
            mi.checked = mi.tag.PRIVATE.index === colIdx;
        }

        this.setGroupByColumnIdx(colIdx);
    }

    public populateHeaderContextMenu (columns: QuickTableColumn[]): void {
        const callback = (menuItem): void => { this.switchColumnVisibility(menuItem.tag); };

        let len = columns.length;
        const items = [];
        for (let i = 0; i < len; i++) {
            const column = columns[i];
            if (column.hidden || !isValidString(column.headerText)) {
                continue;
            }

            const newItem: any = {};
            newItem.text = column.headerText;
            newItem.checked = column.visible;
            newItem.canCheck = true;
            newItem.enabled = true;
            newItem.tag = column;
            newItem.event = callback;

            items.push(newItem);
        }

        if (this.AssociatedItem?.NeedSortMenu()) {
            items.sort(QuickTable.QuickTableMenuComparer);
        }

        len = items.length;
        for (let i = 0; i < len; i++) {
            const column = items[i].tag;
            if (column.beginGroup) {
                items.splice(i, 0, { separator: true });
                i++;
            }
        }

        if (items.length > 0) {
            const newItem = { // Create 'Reset to defaults' Context Menu element
                text: Resources.getResource('panel.menu.resetToDefault'),
                canCheck: false,
                enabled: true,
                event: this.resetContextMenuToDefault.bind(this)
            };
            items.push({ separator: true });
            items.push(newItem);
        }

        this.headerContextMenuItems = items;
    }

    public resetContextMenuToDefault (): void {
        const header = this.headerContextMenuItems;
        for (let i = 0; i < header.length; i++) {
            const item = header[i];
            if (item.canCheck) {
                item.tag.visible = item.tag.visibleDefault;
                item.checked = item.tag.visible;
            }
        }

        this.needRedrawBackground = true;
        this.needRedraw = true;

        if (contextMenuHandler?.isShowed()) {
            contextMenuHandler.Hide();
        }
        this.OnVisibleColumnChanged.Raise(this.getVisibleColumn(), this);
    }

    public static QuickTableMenuComparer (x, y): number {
        if (x && y) {
            return x.tag.MenuIndex - y.tag.MenuIndex;
        } else {
            return 0;
        }
    }

    public setTableContextMenuItems (newItems): void {
        if (isNullOrUndefined(newItems)) {
            return;
        }

        this.tableContextMenuItems = newItems;
    }

    public ColorColumns (): void {
        for (let i = 0; i < this.columns.length; i++) {
            if (/* columns[i].colouringMode != ColouringModes.Signed || */ !this.columns[i].visible) {
                continue;
            }
            for (let j = 0; j < this.rowsArray.length; j++) {
                if (this.rowsArray[j].isGroupRow) {
                    continue;
                }

                if (this.rowsArray[j].cells.length === 0) {
                    break;
                }

                if (this.rowsArray[j].cells[i].value)// is double)
                {
                    this.rowsArray[j].ColorCells(i, this.rowsArray[j].cells[i].value, 0);
                }
            }
        }
    }

    // TODO.
    public createTableContextMenuItems (): any[] {
        let menuItems = [];

        if (!isNullOrUndefined(this.tableContextMenuItems)) {
            menuItems = menuItems.concat(this.tableContextMenuItems);
        }

        if (this.allowGroupBy && this.groupByContextMenuItems) {
            menuItems = menuItems.concat(this.groupByContextMenuItems);
        }

        return menuItems;
    }

    public onResize (): void {
    // Scroll resizing.
        this.scroll.setBounds(this.getScrollRect());

        this.onResizeCorrectColumnsWidth();

        this.prevWidth = this.width;

        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    public getScrollRect (): Rectangle {
        const totalHeinght = this.ShowTotals ? 25 : 0;
        // var left = this.width - Scroll.SCROLL_WIDTH - 1;
        const left = this.width - Scroll.SCROLL_WIDTH;
        // var top = this.showHeader ? this.rowHeight + 1 : this.borderWidth;
        const top = this.showHeader ? this.rowHeight : 0;
        // var height = this.height - 2 * this.borderWidth - top + 1;
        const height = this.height - /* 2 * this.borderWidth - */ top - totalHeinght;
        const width = Scroll.SCROLL_WIDTH;

        return new Rectangle(left, top, width, height);
    }

    public SetScrollHidden (value: boolean): void {
        if (!isNullOrUndefined(this.scroll)) {
            this.scroll.Hidden = value;
        }
    }

    // TODO. Use it for refactoring!
    public getRowsArrayToDraw (): Array<QuickTableRow<ItemType>> {
        const resultRowsArray: Array<QuickTableRow<ItemType>> = [];
        const scrollIndex = this.scroll.scrollIndex;
        let visibleRowCounter = 0;
        let curY = this.showHeader ? this.rowHeight : this.borderWidth;
        const clientH = Math.floor(this.height);
        const rowsArray = this.rowsArray;
        const len = rowsArray.length;
        for (let i = 0; i < len; i++) {
            if (curY >= clientH) {
                break;
            }

            const row = rowsArray[i];
            if (!row.visible) {
                continue;
            }

            visibleRowCounter++;
            if (visibleRowCounter - scrollIndex < 0) {
                continue;
            }

            resultRowsArray.push(row);
            curY += this.rowHeight;
        }

        return resultRowsArray;
    }

    public getRowsCountByHeight (): number {
        return Math.floor(this.height / this.rowHeight);
    }

    // TODO. Optimize.
    // Use this method instead of scroll.scrollIndex.
    public getFirstVisibleScrollIndex (): number {
        const visibleFirstScrollIndex = this.scroll.scrollIndex;
        const rowsArray = this.rowsArray;
        const len = rowsArray.length;
        let visibleRowsCount = 0;
        for (let i = 0; i < len; i++) {
            if (visibleRowsCount === visibleFirstScrollIndex) {
                return i;
            }
            const row = rowsArray[i];
            if (row.visible) {
                visibleRowsCount++;
            }
        }

        return 0;
    }

    public updateScrollElementsCount (): void {
        let count = 0;
        const rowsArray = this.rowsArray;
        const len = rowsArray.length;
        for (let i = 0; i < len; i++) {
            if (rowsArray[i].visible) {
                count++;
            }
        }

        const scroll = this.scroll;
        if (!isNullOrUndefined(scroll)) {
            scroll.elementHeight = this.rowHeight;
            scroll.setScrollElementsCount(count);
        }
    }

    public SwitchDisplayIndex (column1: QuickTableColumn, column2: QuickTableColumn, prev: boolean): void {
        if (column1 == null || column2 == null || column1 === column2) {
            return;
        }

        let tempArray = [];
        tempArray = tempArray.concat(this.sortedColumns);
        ArrayUtils.RemoveElementFromArray(tempArray, column1);
        tempArray.splice(tempArray.indexOf(column2) + (prev ? 0 : 1), 0, column1);

        for (let i = 0; i < tempArray.length; i++) {
            tempArray[i].displayedIndex = i;
        }

        this.UpdateSortedColumns();

        // this.needRedraw = true;
        this.needRedrawBackground = true;

        // call event
        if (this.ColumnDisplayIndexChanged != null) {
            this.ColumnDisplayIndexChanged.Raise(this, this.sortedColumns.indexOf(column1));
        }
    }

    public static readonly ResizeColumnsAreaPixel = 3;
    public static readonly MouseOffset = 5;

    public themeChange (): void {
        this.BackColor = ThemeManager.CurrentTheme.quickTableBackColor;
        this.gridColor = ThemeManager.CurrentTheme.quickTableGridColor;
        this.headerBackColor = ThemeManager.CurrentTheme.quickTableHeaderBackColor;
        this.headerBackBrush = new SolidBrush(this.headerBackColor);
        this.headerForeColor = ThemeManager.CurrentTheme.quickTableHeaderForeColor;
        this.headerTextColorBrush = new SolidBrush(this.headerForeColor);
        this.ForeColor = ThemeManager.CurrentTheme.quickTableForeColor;
        this.color = ThemeManager.CurrentTheme.quickTableColor;
        this.selectedBackColor = ThemeManager.CurrentTheme.TableSelectionBackColor;
        this.selectedForeColor = ThemeManager.CurrentTheme.TableSelectionForeColor;
        this.hoveredBackColor = ThemeManager.CurrentTheme.TableHoverBackColor;
        this.hoveredForeColor = this.selectedForeColor;
        this.hoveredBorderColor = ThemeManager.CurrentTheme.TableBorderHoverColor;
        this.alternatingRowBackColor = ThemeManager.CurrentTheme.quickTableAlternatingRowBackColor;
        this.alternatingRowForeColor = ThemeManager.CurrentTheme.quickTableForeColor;
        this.highlightSuperItemBackColor = ThemeManager.CurrentTheme.quickTableHighlightSuperItemBackColor;
        this.rowBackColor = ThemeManager.CurrentTheme.quickTableRowBackColor;
        this.totalsBackColor = ThemeManager.CurrentTheme.quickTableHeaderBackColor;
        this.totalsForeColor = ThemeManager.CurrentTheme.TableGoldColor;

        this.cellFont = ThemeManager.CurrentTheme.quickTableCellFont;
        this.cellFontObj = Font.parseFontString(this.cellFont);
        this.cellFontSize = this.cellFontObj.Height;

        this.headerFont = ThemeManager.CurrentTheme.quickTableHeaderFont;
        this.headerShadowColor = ThemeManager.CurrentTheme.quickTableHeaderShadowForeColor;
        this.columnSliderColor = ThemeManager.CurrentTheme.QuickTable_BlueColumnResizeColor;

        this.dragDefaultPen = new Pen(ThemeManager.CurrentTheme.quickTableCellDragDefaultColor, 1, [3.5, 1.5]);
        this.dragHighlightPen = new Pen(this.columnSliderColor, 1, [3.5, 1.5]);
        this.editHighlightPen = new Pen(this.columnSliderColor, 1, [3, 2.5]);

        this.tableGroupOpenImg = ThemeManager.CurrentTheme.tableGroupOpenImg;
        this.tableGroupClosedImg = ThemeManager.CurrentTheme.tableGroupClosedImg;

        // this.tableGroupBackDefaultImg = App.ThemeManager.CurrentTheme.tableGroupBackDefaultImg;
        // this.tableGroupBackHoverImg = App.ThemeManager.CurrentTheme.tableGroupBackHoverImg;

        this.tableGroupRowDefaultBrush = new SolidBrush(ThemeManager.CurrentTheme.TableGroupRowDefault);
        this.tableGroupRowHoveredBrush = new SolidBrush(ThemeManager.CurrentTheme.TableGroupRowHovered);

        this.scroll.themeChange();
        this.HeaderFont = ThemeManager.CurrentTheme.QuickTableHeaderFont;

        this.populateHeaderContextMenu(this.columns);

        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    // TODO. Call only from QuickTableRactive.
    public localize (): void {
        let len = this.columns.length;
        for (let i = 0; i < len; i++) {
            this.columns[i].headerText = Resources.getResource(this.columns[i].headerKey);
        }
        this.LinkText = Resources.getResource('InstrumentDetailsPanel.YTM.RefreshLink'); // For DrawLinkWithText
        // TODO. Remove after implementing groups.
        const rows = this.rowsArray;
        len = rows ? rows.length : 0;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (row.isGroupRow) {
                (row as QuickTableGroupRow<ItemType>).localize();
            }
        }

        this.populateHeaderContextMenu(this.columns);
        this.createGroupByContextMenuItems(this.columns);

        this.needRedraw = true;
    }

    public getColumnByHeaderKey (headerKey: string): QuickTableColumn {
        const columns = this.columns;
        if (isNullOrUndefined(columns)) return null;

        for (let i = 0, len = columns.length; i < len; i++) {
            const col = columns[i];
            if (col.headerKey === headerKey) {
                return col;
            }
        }

        return null;
    }

    public getCellRectangleRelativeToCanvas (row: QuickTableRow<ItemType>, columnIndex: number): Rectangle {
        const sortedColumns = this.sortedColumns;
        const len = sortedColumns.length;
        let columnLeft = 0;
        let cellW = 0;
        for (let i = 0; i < len; i++) {
            const cc = sortedColumns[i];
            if (!cc.visible) {
                continue;
            }
            cellW = cc.PRIVATE.drawingWidth;

            if (i === columnIndex) {
                break;
            }
            columnLeft += cellW;
        }

        const rowRect = row.displayRectangle;
        // TODO.
        let y = rowRect.Y;
        if (rowRect.IsEmpty()) {
            const rowIdx = this.rowsArray.indexOf(row);
            if (!this.AddToEnd && rowIdx === 0) {
                y = this.showHeader ? this.rowHeight : this.borderWidth;
            }
        }

        return new Rectangle(columnLeft, y, cellW, this.rowHeight);
    }

    // TODO. Refactor. isIntColumn.
    public GetGroupKey (row: QuickTableRow<ItemType>): any {
        const groupByColumnIndex = this.groupByColumnIndex;
        if (groupByColumnIndex === -1) {
            return null;
        }

        return row.cells[this.groupByColumnIndex].groupByValue;
    }

    public redraw (): void {
        this.needRedrawBackground = true;
        this.needRedraw = true;
    }

    get xmlSettingsTemplate (): { table } { return this.TableXmlSettings; }
    set xmlSettingsTemplate (xml) { this.TableXmlSettings = xml; }

    get TableXmlSettings (): { table } {
        const table: any = {};

        table.sortIndex = this.sortIndex;
        table.sortOrder = this.asc ? 1 : 0;

        const colsArr = [];

        const columns = this.columns;
        for (let i = 0, len = columns.length; i < len; i++) {
            const col = columns[i];
            colsArr.push({
                name: col.headerKey,
                headerText: col.headerText,
                width: col.width,
                visible: col.visible ? 1 : 0,
                displayIndex: col.displayedIndex
            });
        }

        table.columns = colsArr;

        return { table };
    }

    set TableXmlSettings (xml) {
        if (isNullOrUndefined(xml)) return;

        const table = xml.table;
        if (isNullOrUndefined(table)) return;

        if (table.hasOwnProperty('sortIndex')) {
            this.sortIndex = parseInt(table.sortIndex);
        }

        if (table.hasOwnProperty('sortOrder')) {
            this.asc = !!parseInt(table.sortOrder);
        }

        const colsArr = table.columns;
        if (!isNullOrUndefined(colsArr)) {
            for (let i = 0, len = colsArr.length; i < len; i++) {
                const colData = colsArr[i];

                const col = this.getColumnByHeaderKey(colData.name);
                if (isNullOrUndefined(col)) continue;

                if (colData.hasOwnProperty('headerText')) {
                    col.headerText = colData.headerText;
                }

                if (colData.hasOwnProperty('width')) {
                    col.width = parseFloat(colData.width);
                }

                if (colData.hasOwnProperty('visible')) {
                    col.visible = !!parseInt(colData.visible);
                }

                if (colData.hasOwnProperty('displayIndex')) {
                    col.displayedIndex = parseInt(colData.displayIndex);
                }
            }
        }

        if (this.sortIndex >= this.columns.length) {
            this.sortIndex = -1;
        }

        this.UpdateSortedColumns();
        this.populateHeaderContextMenu(this.columns);
    }
}

export enum QuickTableZone {
    Cell = 'Cell',
    Header = 'Header',
    Grid = 'Grid',
    Table = 'Table',
    VerticalResizeSeparator = 'VerticalResizeSeparator',
    VerticalScroll = 'VerticalScroll',
    VerticalScrollActiveZone = 'VerticalScrollActiveZone',
    HeaderGrid = 'HeaderGrid',
    TopScrollButton = 'TopScrollButton',
    BottomScrollButton = 'BottomScrollButton'
}

export enum QuickTableMovingType {
    VerticalScroll = 'VerticalScroll',
    HorizontalScroll = 'HorizontalScroll',
    ColumnResize = 'ColumnResize',
    ColumnMoving = 'ColumnMoving',
    None = 'None',
}

export enum GridVisibilityType {
    None = 0,
    Vertical = 1,
    Horizontal = 2,
    Both = 3
}

class QuickTableDrawingCellEventArgs {
    public table: any;
    public tableItem: any;
    public columnIndex: any;

    public cell: any;
    public font: any;
    public ForeColor: any;
    public BackColor: any;
    public x: any;
    public y: any;
    public width: any;
    public height: any;
    public handled: any;

    constructor (
        table,
        rowItem,
        columnIndex,
        cell,
        curFont,
        foreColor,
        backColor,
        x,
        y,
        width,
        height) {
        this.table = table;
        this.tableItem = rowItem;
        this.columnIndex = columnIndex;

        this.cell = cell;
        this.font = curFont;
        this.ForeColor = foreColor;
        this.BackColor = backColor;
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.handled = false;
    }
}

class QTGroupStorageItem {
    public groupHeaderRow: QuickTableGroupRow | null = null;
    // For lookup.
    public groupRowsDict = {};
    // For viewing.
    public groupRowsArray = [];
    // Fucking sorting
    public GroupIndex: any = null;
}
